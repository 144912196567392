import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil} from '@fortawesome/free-solid-svg-icons';


const Drafts = () => {
  
    return (
        <div className='Drafts container w-75 p-3'>
                    
                <div className='d-flex justify-content-center m-3'>
                    <button className="rounded-circle d-inline-block text-center text-white h3 profilebtn" style={{ width: '60px', height: '60px', lineHeight: '40px' }}>PJ</button>
                </div>
                <div className='d-flex justify-content-center h3 fw-bold'>
                     <p>Your Drafts</p>
                </div>
            
                <hr />
    
       <div className="tab-content d-block mx-auto w-50 p-3">
            <div className='content d-flex justify-content-center align-item-center'>
                    <FontAwesomeIcon className='me-3 mt-2 Bmicon' icon={faPencil} />
                    <p> <strong>You haven’t saved any drafts yet </strong><br />
                        When you save a message or document as a draft,<br /> 
                        it’ll show up here. Your drafts aren’t visible to<br />
                        anyone else.
                    </p>
            </div>
    
    </div>
    </div>
      )
  
}

export default Drafts