import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBars, faMessage, faComment, faClock, faSearch, faSmile } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownToggle, DropdownMenu } from 'react-bootstrap';
import { faBell, } from '@fortawesome/free-solid-svg-icons';
import { SiBasecamp, SiVerizon } from 'react-icons/si';
import { IoBookmark } from 'react-icons/io5';
import { GoClockFill } from "react-icons/go";
import { MdRocketLaunch } from 'react-icons/md';
import { AiFillFileText, AiFillSchedule } from "react-icons/ai";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();
const HomeNav = ({ onLogout }) => {

  const navigate = useNavigate();
  const toggled = () => setDropdownOpen((prevState) => !prevState);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedAnyone, setSelectedAnyone] = useState('');
  const [inputValue, setInputValue] = useState('');



  //  Ping search people

  const acc = [

    {
      id: 1,
      username: "Prakash Kumar",
      profilePic: 'photo.JPG',
      companyName: "Webnexs",
      role: "Web Developer",
      location: "Banglore",

    },
    {
      id: 2,
      username: "Balaji",
      profilePic: null,
      companyName: "Webnexs",
      role: "Web Developer",
      location: "Kerala",

    },
    {
      id: 3,
      username: "Arun Kmumar",
      profilePic: null,
      companyName: "Webnexs",
      role: "Web Developer",
      location: "chennai",

    },
    {
      id: 4,
      username: "Ganesh",
      profilePic: null,
      companyName: "Webnexs",
      role: "Web Developer",
      location: "chennai",

    }
  ];

  const [query, setQuery] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);


  const handleCreate = (options) => {
    setSelectedUsers(options);
  };

  const groupSelectedOptions = () => {
    const grouped = selectedUsers.reduce((acc, option) => {
      const group = acc.find(g => g.username === option.username);
      if (group) {
        group.items.push(option);
      } else {
        acc.push({ username: option.username, items: [option] });
      }
      return acc;
    }, []);
    return grouped;
  };

  const getInitials = (name) => {
    const nameArray = name.split(' ');
    const initials = nameArray.map(n => n.charAt(0)).join('').toUpperCase();
    return initials;
  };

  // const formatOptionLabel = ({ label, username, profilePic }) => (
  //   <div style={{ display: 'flex', alignItems: 'center' }}>
  //     <img
  //       src={profilePic}
  //       alt="NA"
  //       style={{ width: 24, height: 24, borderRadius: '50%', marginRight: 10 }}
  //     />
  //     {`${username}`}
  //   </div>
  // );


  const formatOptionLabel = ({ username, profilePic }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {profilePic ? (
        <img
          src={profilePic}
          alt="Profile"
          style={{ width: 24, height: 24, borderRadius: '50%', marginRight: 10 }}
        />
      ) : (
        <span
          style={{
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            backgroundColor: '#4caf50', // Background color for initials
            color: '#fff', // Text color for initials
            marginRight: 10,
            fontSize: '12px',
          }}
        >
          {getInitials(username)}
        </span>
      )}
      {username}
    </div>
  );



  const handleChange = (event) => {
    setSelectedAnyone(event.target.value);
  };
  const [selecteEverything, setSelectedEverything] = useState('');
  const handleChanges = (event) => {
    setSelectedEverything(event.target.value);
  };

  const Everything = [
    { value: '', label: 'Search Everything' },
    { value: 'Card tables', label: 'Card tables' },
    { value: 'Chats', label: 'Chats' },
    { value: 'Check-ins', label: 'Check-ins' },
    { value: 'Client emails', label: 'Client emails' },
    { value: 'Comments', label: 'Comments' },
    { value: 'Documents', label: 'Documents' },
    { value: 'Events', label: 'Events' },
    { value: 'Files', label: 'Files' },
    { value: 'Folders', label: 'Folders' },
    { value: 'Forwarded emails', label: 'Forwarded emails' },
    { value: 'Messages', label: 'Messages' },
    { value: 'Pings', label: 'Pings' },
    { value: 'To-dos', label: 'To-dos' },
  ];
  const Anyone = [
    { value: '', label: 'by Anyone' },
    { value: 'Arul Kumar V', label: 'Arul Kumar V' },
    { value: 'Arun Kumar', label: 'Arun Kumar' },
    { value: 'ArunKumar AD', label: 'ArunKumar AD' },
    { value: 'Bala', label: 'Bala' },
    { value: 'Balaji', label: 'Balaji' },
    { value: 'David Bright Livingstone', label: 'David Bright Livingstone' },
    { value: 'Gowri R', label: 'Gowri R' },
    { value: 'Jerin Pandian R', label: 'Jerin Pandian R' },
    { value: 'Kannan M', label: 'Kannan M' },
    { value: 'Kavitha E', label: 'Kavitha E' },
    { value: 'Kowsalya H', label: 'Kowsalya H' },
    { value: 'Manivel', label: 'Manivel' },
    { value: 'Manoj', label: 'Manoj' },
    { value: 'Personal mastery', label: 'Personal mastery' },
    { value: 'Announcement', label: 'Announcement' },
    { value: 'FYI', label: 'FYI' },
    { value: 'Pitch', label: 'Pitch' },
    { value: 'Heartbeat', label: 'Heartbeat' },
    { value: 'Question', label: 'Question' },
    { value: 'DM', label: 'DM' },
    { value: 'Personal mastery', label: 'Personal mastery' },
    { value: 'Announcement', label: 'Announcement' },
    { value: 'FYI', label: 'FYI' },
    { value: 'Pitch', label: 'Pitch' },
    { value: 'Heartbeat', label: 'Heartbeat' },
    { value: 'Question', label: 'Question' },
    { value: 'DM', label: 'DM' },
    { value: 'Personal mastery', label: 'Personal mastery' },
    { value: 'Announcement', label: 'Announcement' },
    { value: 'FYI', label: 'FYI' },
    { value: 'Pitch', label: 'Pitch' },
    { value: 'Heartbeat', label: 'Heartbeat' },
    { value: 'Question', label: 'Question' },
    { value: 'DM', label: 'DM' },
    { value: 'Personal mastery', label: 'Personal mastery' },
  ];


  const [selecteEverywhere, setSelectedEverywhere] = useState('');
  const handleChangesd = (s) => {
    setSelectedEverywhere(s.target.value);
  };


  const Everywhere = [
    { value: '', label: 'Everywhere' },
    { value: 'Webnexs HQ', label: 'Webnexs HQ' },
  ];

  const handleLogout = () => {
    // Perform logout actions here
    onLogout(); // Call the onLogout function passed as props
    navigate("/login"); // Redirect to login page after logout
  };
  return (
    <div className="Header ">
      <Navbar className='Navbar fixed' variant="dark" expand="lg">
        <div className="logohead">
          <img src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public' className='text-light mb-1 me-1' style={{ width: '32px', height: '32px' }} />
          {/* <img src={logo} alt="Logo" style={{ width: '35px', height: '35px' }} /> */}
          <Navbar.Brand to="#home" className="text-white fw-bold">TeamNex</Navbar.Brand>
        </div>
        <Navbar.Toggle className='aaa' aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto ">
            <Link to='/' className="text-white text-decoration-none mx-2 fw-bold mt-1"> <FontAwesomeIcon icon={faHome} /> Home</Link>
            <Link to="#Line up" className="text-white text-decoration-none mx-2 fw-bold mt-1"> <FontAwesomeIcon icon={faBars} />  Line up</Link>
            <Dropdown toggle={toggled} className=" PingsDw">
              <DropdownToggle className="PingsDw p-0">
                <div className="text-white mx-2 fw-bold"> <FontAwesomeIcon icon={faMessage} />  Pings</div>
              </DropdownToggle>
              <DropdownMenu className='PingsDw Pingshw '>
                {/* <div className="p-3 ">

                  {/* React select Package *

                  <Select
                    closeMenuOnSelect={false}
                    className='PingsText'
                    components={animatedComponents}
                    placeholder='Start a private chat with...'
                    isMulti
                    options={acc}
                    // getOptionLabel={(option) => `${option.username}`} // Displays label and username
                    getOptionValue={(option) => option.username}
                    onChange={handleCreate}
                    formatOptionLabel={formatOptionLabel}
                  />


                  <button type="button" className="btn pingBtn">Ping`em</button>


                </div> */}

                <div className="p-3">
                  <div className="d-flex align-items-stretch">
                    {/* React select Package */}
                    <div style={{ flexGrow: 25 }}>
                      <Select
                        closeMenuOnSelect={false}
                        className='PingsText text-white'
                        components={animatedComponents}
                        placeholder='Start a private chat with...'
                        isMulti
                        options={acc}
                        getOptionValue={(option) => option.username}
                        onChange={handleCreate}
                        formatOptionLabel={formatOptionLabel}
                      />
                    </div>

                    <button type="button" className="btn pingBtn">Ping`em</button>
                  </div>
                </div>


                <div className='ms-3 p-3 pingsDot' style={{}}>
                  <p className='text-light'>Pings are private chats with one or more people
                    Start your first conversation by typing someone’s name.</p>
                </div>
              </DropdownMenu>
            </Dropdown>
            <Dropdown toggle={toggled} className=" PingsDw ">
              <DropdownToggle className="PingsDw p-0">
                <div className="text-white mx-2 fw-bold"> <FontAwesomeIcon icon={faComment} /> Hey!</div>
              </DropdownToggle>
              <DropdownMenu className='PingsDw Pingshw'>
                <div className="ms-3 p-2 d-flex justify-content-between">
                  <p className='heyNew'>New for you</p>
                  <Link to="#" className='text-light text-decoration-none'>Mark all read</Link>
                </div>
                <div className='ms-4'>
                  <p className='text-light'>What was that on your plate today?</p>
                </div>
              </DropdownMenu>
            </Dropdown>
            <Link to="/Activitydd" className="text-white mx-2 fw-bold text-decoration-none mt-1"> <FontAwesomeIcon icon={faClock} />  Activity</Link>
            <Dropdown toggle={toggled} className=" StuffDw ">
              <DropdownToggle className=" StuffDw p-0 ">
                <div className="text-white mx-2 fw-bold"> <FontAwesomeIcon icon={faSmile} />My Stuff</div>
              </DropdownToggle>
              <DropdownMenu className='StuffDw myStuffhw p-3 overflow-auto'>
                <Link to="/Assignment" className="bg_my_Stuff text-white text-decoration-none">
                  <div className='p-2'> <button className=' me-2 mystuffBtn'><SiVerizon /></button>My Assignments</div>
                </Link>
                <Link to="/Bookmark" className="bg_my_Stuff text-white text-decoration-none">
                  <div className='p-2'> <button className=' me-2 mystuffBtn'><IoBookmark /></button>My Bookmarks</div>
                </Link>
                <Link to="/Schedule" className="bg_my_Stuff text-white text-decoration-none">
                  <div className='p-2'> <button className=' me-2 mystuffBtn'><AiFillSchedule /></button>My Schedule</div>
                </Link>
                <Link to="/Drafts" className='bg_my_Stuff text-white text-decoration-none'>
                  <div className="p-2"> <button className=' me-2 mystuffBtn'><AiFillFileText /></button>My Drafts</div>
                </Link>
                <Link to="/Activitydd" className='bg_my_Stuff text-white text-decoration-none'>
                  <div className="p-2"> <button className=' me-2 mystuffBtn'><GoClockFill /></button>My Recent Activity</div>
                </Link>
                <Link to="/Boosts" className='bg_my_Stuff text-white text-decoration-none' >
                  <div className="p-2"> <button className=' me-2 mystuffBtn'><MdRocketLaunch /></button>My Boosts</div>
                </Link>
                <hr className="line" />
                <div>
                  <Link to="#" className='text-decoration-none'><p className='text-white fw-bold'>Recently visited</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>All the latest activity</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>My assignments</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>Scheduled projects</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>Adminland</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>webnexs HQ</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>Answers to “What was that on your plate today?” - webnexs...</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>Campfire - webnexs HQ</p></Link>
                </div>
                <div className='text-light d-flex justify-content-center'>Press<kbd>ctrl + J</kbd>for more recent history</div>
              </DropdownMenu>
            </Dropdown>
            <Dropdown toggle={toggled} className=" PingsDw ">
              <DropdownToggle className="PingsDw p-0 ">
                <div className="text-white mx-2 fw-bold"><FontAwesomeIcon icon={faSearch} />Find</div>
              </DropdownToggle>
              <DropdownMenu className='PingsDw Pingshw'>
                <div className="mt-3 ms-3 me-3 ">
                  <input type="text" className='findText' />
                </div>
                <div className='p-3'>
                  <span className="text-start ms-3">
                    <select
                      className='findMessages text-light'
                      id="day-selector"
                      value={selecteEverything}
                      onChange={handleChanges}
                    >
                      {Everything.map((Everything) => (
                        <option key={Everything.value} value={Everything.value}>
                          {Everything.label}
                        </option>
                      ))}
                    </select>
                  </span>
                  <span className="text-center ms-3">
                    <select
                      className='findMessages text-light input--select'
                      id="day-selector"
                      value={selectedAnyone}
                      onChange={handleChange}
                    >
                      {Anyone.map((Anyone) => (
                        <option key={Anyone.value} value={Anyone.value}>
                          {Anyone.label}
                        </option>
                      ))}
                    </select>
                  </span>
                  <span className="text-end ms-3">
                    <select
                      className='findMessages text-light'
                      id="day-selector"
                      value={selecteEverywhere}
                      onChange={handleChangesd}
                    >
                      {Everywhere.map((Everywhere) => (
                        <option key={Everywhere.value} value={Everywhere.value}>
                          {Everywhere.label}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
              </DropdownMenu>
            </Dropdown>
          </Nav>
          <Nav>
            <Dropdown toggle={toggled} className="ml-2 DDMenu">
              <DropdownToggle className="Profile-btn rounded-circle bg-success text-white p-2">
                <b className='d-flex justify-content-center'>AK</b>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-end'>
                <div className="p-3 dropdown-box">
                  <div className='head'>
                    <h6 className="mb-3 fw-bold text-white"><FontAwesomeIcon icon={faBell} /> Notifications are on <hr style={{ display: 'inline-block', width: '100px', verticalAlign: 'middle', margin: '0 10px' }} /> </h6>
                    <p className='text-white'>You're set to receive email and browser
                      <br />notifications for everything all the time.</p>
                    <button className="bttn text-white m-2">Turn on Focus Mode</button>
                    <Link to="/NotificationSetting" className="d-block text-white mb-2 ">Change your notification settings</Link>
                    <Link to="#" className="d-block text-white mb-4 ">Turn notifications off</Link>
                  </div>
                  <div className='bottom'>
                    <Link to="#" className="d-block mb-3 text-white text-decoration-none fw-bold">Personal Settings <hr style={{ display: 'inline-block', width: '100px', verticalAlign: 'middle', margin: '0 10px' }} /></Link>
                    <Link to="/PersonalCard" className="d-block mb-2 text-white text-decoration-none">Profile, password, 2FA</Link>
                    <Link to="/MyDevices" className="d-block mb-2 text-white text-decoration-none">My devices (Get our free mobile apps)</Link>
                    <Link to="/Preferences" className="d-block mb-2 text-white text-decoration-none">Preferences</Link>
                    <Link to="/OutOfOffice" className="d-block mb-2 text-white text-decoration-none">Out of office</Link>
                    <Link to="#" onClick={handleLogout} className="d-block mb-2 text-white text-decoration-none">Log out</Link>
                  </div>
                </div>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>

      </Navbar>
    </div >
  )
}

export default HomeNav;