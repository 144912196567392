import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark} from '@fortawesome/free-solid-svg-icons';

const Bookmark = () => {
  return (
   <div className='Bookmark container w-75 p-3'>
                
                <div className='d-flex justify-content-center m-3'>
                    <button className="rounded-circle d-inline-block text-center text-white h3 profilebtn" style={{ width: '60px', height: '60px', lineHeight: '40px' }}>PJ</button>
                </div>
                <div className='d-flex justify-content-center h3 fw-bold'>
                     <p>Your Bookmarks</p>
                </div>

                <hr />

               <div className="tab-content d-block mx-auto p-3">
                    <div className='content d-flex justify-content-center align-item-center'>
                           <FontAwesomeIcon className='me-3 mt-2 Bmicon' icon={faBookmark} />
                            <p> <strong>You haven’t bookmarked anything yet</strong><br />
                                Hit the Bookmark option from the menu on any<br /> 
                                message,doc, to-do list, etc. to save it here.</p>
                    </div>
       
        </div>
    </div>
    
  )
}

export default Bookmark