
const CampfireShow = ({ data }) => {
  return (
    <div className="cardbox">
      <h2 className="card-title text-white fw-bold fs-5 text-center border-bottom py-2">Campfire</h2>
      <div className=" px-3 pt-2 overflow-hidden">
          {data.map((item, index) => (
            <div className="card-item pb-1" key={index}>
              <div className="item-header d-flex">
                <img alt="profilePic" src={item.profilePic} className="profile-pic" />
                <h3 className="item-title pt-1 ps-2">{item.title}</h3>
              </div>
              <span className="item-paragraph text-white">{item.paragraph.substring(0, 35)}...</span>
              <hr className="textLine m-0" />
            </div>
          ))}
      </div>
    </div>
  );
}

export default CampfireShow;
