import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBars, faMessage, faComment, faClock, faSearch, faSmile } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownToggle, DropdownMenu } from 'react-bootstrap';
import { faBell, } from '@fortawesome/free-solid-svg-icons';
import { SiVerizon } from 'react-icons/si';
import { IoBookmark } from 'react-icons/io5';
import { GoClockFill } from "react-icons/go";
import { MdRocketLaunch } from 'react-icons/md';
import { AiFillFileText, AiFillSchedule } from "react-icons/ai";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from 'axios';

const animatedComponents = makeAnimated();
const HomeNav = ({ onLogout, userData }) => {
  const navigate = useNavigate();
  const toggled = () => setDropdownOpen((prevState) => !prevState);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedAnyone, setSelectedAnyone] = useState('');

  const [dropdownPingOpen, setDropdownPingOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownPingOpen(!dropdownPingOpen);
  };
  const organizationId = userData ? userData.user.organizationId._id : ""
  //  Ping search people
  const [users, setUsers] = useState([])

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/getAllUsers`);
  //       setUsers(response.data);
  //     } catch (error) {
  //       console.error('Error fetching the user data', error);
  //     }
  //   };

  //   fetchUsers();
  // }, []);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleCreate = (options) => {
    setSelectedUsers(options);
  };


  const getInitials = (name) => {
    const nameArray = name.split(' ');
    const initials = nameArray.map(n => n.charAt(0)).join('').toUpperCase();
    return initials;
  };




  const formatOptionLabel = ({ name, profilePhoto }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {profilePhoto ? (
        <img
          src={profilePhoto}
          alt='as'
          style={{ width: 24, height: 24, borderRadius: '50%', marginRight: 10 }}
        />
      ) : (
        <span
          style={{
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            backgroundColor: '#4caf50', // Background color for initials
            color: '#fff', // Text color for initials
            marginRight: 10,
            fontSize: '12px',
          }}
        >
          {getInitials(name)}
        </span>
      )}
      {name}
    </div>
  );


  const handleChange = (event) => {
    setSelectedAnyone(event.target.value);
  };
  const [selecteEverything, setSelectedEverything] = useState('');
  const handleChanges = (event) => {
    setSelectedEverything(event.target.value);
  };

  //Ping chat creation Integration
  const loggedInUserId = '66d594d6905fb32a7c161018'

  const handlePing = async () => {

    // Ensure at least one user is selected
    if (selectedUsers.length === 0) {
      alert('Please select at least one user.');
      return;
    }

    // Create chats for each selected user
    try {
      const responses = await Promise.all(
        selectedUsers.map(async (selectedUser) => {
          const response = await axios.post('http://localhost:3334/api/chats/createChat', {
            user1Id: loggedInUserId,
            user2Id: selectedUser._id,
          });

          console.log("Selected User:", selectedUser._id);
          console.log("Logged-in User:", loggedInUserId);

          // return response.data; // Return chat data
          return { chat: response.data, receiver: selectedUser, sender: loggedInUserId };
        })
      );

      console.log('Chats created', responses);

      // Assuming you want to navigate to the first created chat
      if (responses.length > 0) {
        const { chat, receiver, sender } = responses[0]; // Get the first chat and user data
        navigate(`/chat/${chat.chat._id}`, { state: { userName: receiver.name, userProfilePhoto: receiver.profilePhoto, loggedInUserId: sender.loggedInUserId } }); // Pass data via state
      }
      setDropdownOpen(false);
    } catch (error) {
      console.error('Error creating chat', error);
    }
  };

  const Everything = [
    { value: '', label: 'Search Everything' },
    { value: 'Card tables', label: 'Card tables' },
    { value: 'Chats', label: 'Chats' },
    { value: 'Check-ins', label: 'Check-ins' },
    { value: 'Client emails', label: 'Client emails' },
    { value: 'Comments', label: 'Comments' },
    { value: 'Documents', label: 'Documents' },
    { value: 'Events', label: 'Events' },
    { value: 'Files', label: 'Files' },
    { value: 'Folders', label: 'Folders' },
    { value: 'Forwarded emails', label: 'Forwarded emails' },
    { value: 'Messages', label: 'Messages' },
    { value: 'Pings', label: 'Pings' },
    { value: 'To-dos', label: 'To-dos' },
  ];
  const Anyone = [
    { value: '', label: 'by Anyone' },
    { value: 'Arul Kumar V', label: 'Arul Kumar V' },
    { value: 'Arun Kumar', label: 'Arun Kumar' },
    { value: 'ArunKumar AD', label: 'ArunKumar AD' },
    { value: 'Bala', label: 'Bala' },
    { value: 'Balaji', label: 'Balaji' },
    { value: 'David Bright Livingstone', label: 'David Bright Livingstone' },
    { value: 'Gowri R', label: 'Gowri R' },
    { value: 'Jerin Pandian R', label: 'Jerin Pandian R' },
    { value: 'Kannan M', label: 'Kannan M' },
    { value: 'Kavitha E', label: 'Kavitha E' },
    { value: 'Kowsalya H', label: 'Kowsalya H' },
    { value: 'Manivel', label: 'Manivel' },
    { value: 'Manoj', label: 'Manoj' },
    { value: 'Personal mastery', label: 'Personal mastery' },
    { value: 'Announcement', label: 'Announcement' },
    { value: 'FYI', label: 'FYI' },
    { value: 'Pitch', label: 'Pitch' },
    { value: 'Heartbeat', label: 'Heartbeat' },
    { value: 'Question', label: 'Question' },
    { value: 'DM', label: 'DM' },
    { value: 'Personal mastery', label: 'Personal mastery' },
    { value: 'Announcement', label: 'Announcement' },
    { value: 'FYI', label: 'FYI' },
    { value: 'Pitch', label: 'Pitch' },
    { value: 'Heartbeat', label: 'Heartbeat' },
    { value: 'Question', label: 'Question' },
    { value: 'DM', label: 'DM' },
    { value: 'Personal mastery', label: 'Personal mastery' },
    { value: 'Announcement', label: 'Announcement' },
    { value: 'FYI', label: 'FYI' },
    { value: 'Pitch', label: 'Pitch' },
    { value: 'Heartbeat', label: 'Heartbeat' },
    { value: 'Question', label: 'Question' },
    { value: 'DM', label: 'DM' },
    { value: 'Personal mastery', label: 'Personal mastery' },
  ];


  const [selecteEverywhere, setSelectedEverywhere] = useState('');
  const handleChangesd = (s) => {
    setSelectedEverywhere(s.target.value);
  };


  const Everywhere = [
    { value: '', label: 'Everywhere' },
    { value: 'Webnexs HQ', label: 'Webnexs HQ' },
  ];

  const handleLogout = () => {
    // Perform logout actions here
    onLogout(); // Call the onLogout function passed as props
    navigate("/", { replace: true }); // Redirect to login page after logout
  };
  return (
    <div className="Header ">
      <Navbar className='Navbar fixed' variant="dark" expand="lg">
        <div className="logohead pl-2">
          <img src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public' className='text-light mb-1 me-1' style={{ width: '32px', height: '32px' }} alt='' />
          {/* <img src={logo} alt="Logo" style={{ width: '35px', height: '35px' }} /> */}
          <Navbar.Brand to="#home" className="text-white fw-bold">TeamNex</Navbar.Brand>
        </div>
        <Navbar.Toggle className='aaa' aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto ">
            <Link to={`${organizationId}`} className="text-white text-decoration-none mx-2 fw-bold mt-1"> <FontAwesomeIcon icon={faHome} /> Home</Link>
            <Link to="#Line up" className="text-white text-decoration-none mx-2 fw-bold mt-1"> <FontAwesomeIcon icon={faBars} />  Line up</Link>
            <Dropdown toggle={toggled} className=" PingsDw">
              <DropdownToggle className="PingsDw p-0">
                <div className="text-white mx-2 fw-bold"> <FontAwesomeIcon icon={faMessage} />  Pings</div>
              </DropdownToggle>
              <DropdownMenu className='PingsDw Pingshw ' isOpen={dropdownPingOpen} toggle={toggleDropdown}>
                <div className="p-3">
                  <div className="d-flex align-items-stretch">
                    {/* React select Package */}
                    <div style={{ flexGrow: 25 }}>
                      <Select
                        closeMenuOnSelect={false}
                        className='PingsText text-white'
                        components={animatedComponents}
                        placeholder='Start a private chat with...'
                        isMulti
                        options={users}
                        getOptionValue={(option) => option._id}
                        onChange={handleCreate}
                        formatOptionLabel={formatOptionLabel}
                      />
                    </div>

                    <button type="button" className="btn pingBtn" onClick={handlePing}>Ping`em</button>
                  </div>
                </div>


                <div className='ms-3 p-3 pingsDot' style={{}}>
                  <p className='text-light'>Pings are private chats with one or more people
                    Start your first conversation by typing someone’s name.</p>
                </div>
              </DropdownMenu>
            </Dropdown>
            <Dropdown toggle={toggled} className=" PingsDw ">
              <DropdownToggle className="PingsDw p-0">
                <div className="text-white mx-2 fw-bold"> <FontAwesomeIcon icon={faComment} /> Hey!</div>
              </DropdownToggle>
              <DropdownMenu className='PingsDw Pingshw'>
                <div className="ms-3 p-2 d-flex justify-content-between">
                  <p className='heyNew'>New for you</p>
                  <Link to="#" className='text-light text-decoration-none'>Mark all read</Link>
                </div>
                <div className='ms-4'>
                  <p className='text-light'>What was that on your plate today?</p>
                </div>
              </DropdownMenu>
            </Dropdown>
            <Link to={`${organizationId}/activity`} className="text-white mx-2 fw-bold text-decoration-none mt-1"> <FontAwesomeIcon icon={faClock} />  Activity</Link>
            <Dropdown toggle={toggled} className=" StuffDw ">
              <DropdownToggle className=" StuffDw p-0 ">
                <div className="text-white mx-2 fw-bold"> <FontAwesomeIcon icon={faSmile} />My Stuff</div>
              </DropdownToggle>
              <DropdownMenu className='StuffDw myStuffhw p-3 overflow-auto'>
                <Link to={`${organizationId}/my/assignments`} className="bg_my_Stuff text-white text-decoration-none">
                  <div className='p-2'> <button className=' me-2 mystuffBtn'><SiVerizon /></button>My Assignments</div>
                </Link>
                <Link to={`${organizationId}/my/bookmarks`} className="bg_my_Stuff text-white text-decoration-none">
                  <div className='p-2'> <button className=' me-2 mystuffBtn'><IoBookmark /></button>My Bookmarks</div>
                </Link>
                <Link to={`${organizationId}/my/schedules`} className="bg_my_Stuff text-white text-decoration-none">
                  <div className='p-2'> <button className=' me-2 mystuffBtn'><AiFillSchedule /></button>My Schedule</div>
                </Link>
                <Link to={`${organizationId}/my/drafts`} className='bg_my_Stuff text-white text-decoration-none'>
                  <div className="p-2"> <button className=' me-2 mystuffBtn'><AiFillFileText /></button>My Drafts</div>
                </Link>
                <Link to="/activity" className='bg_my_Stuff text-white text-decoration-none'>
                  <div className="p-2"> <button className=' me-2 mystuffBtn'><GoClockFill /></button>My Recent Activity</div>
                </Link>
                <Link to={`${organizationId}/my/boosts`} className='bg_my_Stuff text-white text-decoration-none' >
                  <div className="p-2"> <button className=' me-2 mystuffBtn'><MdRocketLaunch /></button>My Boosts</div>
                </Link>
                <hr className="line" />
                <div>
                  <Link to="#" className='text-decoration-none'><p className='text-white fw-bold'>Recently visited</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>All the latest activity</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>My assignments</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>Scheduled projects</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>Adminland</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>webnexs HQ</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>Answers to “What was that on your plate today?” - webnexs...</p></Link>
                  <Link to="#" className='text-decoration-none'><p className='text-white '>Campfire - webnexs HQ</p></Link>
                </div>
                <div className='text-light d-flex justify-content-center'>Press<kbd>ctrl + J</kbd>for more recent history</div>
              </DropdownMenu>
            </Dropdown>
            <Dropdown toggle={toggled} className=" PingsDw ">
              <DropdownToggle className="PingsDw p-0 ">
                <div className="text-white mx-2 fw-bold"><FontAwesomeIcon icon={faSearch} />Find</div>
              </DropdownToggle>
              <DropdownMenu className='PingsDw Pingshw'>
                <div className="mt-3 ms-3 me-3 ">
                  <input type="text" className='findText' />
                </div>
                <div className='p-3'>
                  <span className="text-start ms-3">
                    <select
                      className='findMessages text-light'
                      id="day-selector"
                      value={selecteEverything}
                      onChange={handleChanges}
                    >
                      {Everything.map((Everything) => (
                        <option key={Everything.value} value={Everything.value}>
                          {Everything.label}
                        </option>
                      ))}
                    </select>
                  </span>
                  <span className="text-center ms-3">
                    <select
                      className='findMessages text-light input--select'
                      id="day-selector"
                      value={selectedAnyone}
                      onChange={handleChange}
                    >
                      {Anyone.map((Anyone) => (
                        <option key={Anyone.value} value={Anyone.value}>
                          {Anyone.label}
                        </option>
                      ))}
                    </select>
                  </span>
                  <span className="text-end ms-3">
                    <select
                      className='findMessages text-light'
                      id="day-selector"
                      value={selecteEverywhere}
                      onChange={handleChangesd}
                    >
                      {Everywhere.map((Everywhere) => (
                        <option key={Everywhere.value} value={Everywhere.value}>
                          {Everywhere.label}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
              </DropdownMenu>
            </Dropdown>
          </Nav>
          <Nav>
            <Dropdown toggle={toggled} className="ml-2 DDMenu">
              <DropdownToggle className="Profile-btn rounded-circle bg-success text-white p-2">
                <b className='d-flex justify-content-center position-relative Profile-icon'>AK</b>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-end'>
                <div className="p-3 dropdown-box">
                  <div className='head'>
                    <h6 className="mb-3 fw-bold text-white"><FontAwesomeIcon icon={faBell} /> Notifications are on <hr style={{ display: 'inline-block', width: '100px', verticalAlign: 'middle', margin: '0 10px' }} /> </h6>
                    <p className='text-white'>You're set to receive email and browser
                      <br />notifications for everything all the time.</p>
                    <button className="bttn text-white m-2">Turn on Focus Mode</button>
                    <Link to={`${organizationId}/my/notifications/settings/edit`} className="d-block text-white mb-2 ">Change your notification settings</Link>
                    <Link to="#" className="d-block text-white mb-4 ">Turn notifications off</Link>
                  </div>
                  <div className='bottom'>
                    <Link to="#" className="d-block mb-3 text-white text-decoration-none fw-bold">Personal Settings <hr style={{ display: 'inline-block', width: '100px', verticalAlign: 'middle', margin: '0 10px' }} /></Link>
                    <Link to={`${organizationId}/my/profile`} className="d-block mb-2 text-white text-decoration-none">Profile, password, 2FA</Link>
                    <Link to={`${organizationId}/my/devices`} className="d-block mb-2 text-white text-decoration-none">My devices (Get our free mobile apps)</Link>
                    <Link to={`${organizationId}/my/preference`} className="d-block mb-2 text-white text-decoration-none">Preferences</Link>
                    <Link to={`${organizationId}/my/out_of_office`} className="d-block mb-2 text-white text-decoration-none">Out of office</Link>
                    <Link to="#" onClick={handleLogout} className="d-block mb-2 text-white text-decoration-none">Log out</Link>
                  </div>
                </div>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>

      </Navbar>
    </div >
  )
}

export default HomeNav;