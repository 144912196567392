import React from 'react'

const Someones_Assignment = () => {
  return (
    <div className='Someone_Act container p-3'>
        <div className='d-flex justify-content-center align-item-center fw-bold h2 pt-2'>
                 <p>Whose assignments would you like to see?</p>
        </div>
        <hr />
        <div className='d-flex justify-content-center m-2'>
                <input className='form-control w-75 project-ip' type="text" name="" id="" placeholder='Jump to a person...' />
        </div>
    </div>
  )
}

export default Someones_Assignment