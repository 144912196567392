import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaPlus } from "react-icons/fa";
import { RiPieChartFill } from "react-icons/ri";
import axios from 'axios';
import { Link } from 'react-router-dom';

const ToDos = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showTodoForm, setShowTodoForm] = useState(false);
    const [listName, setListName] = useState('');
    const [details, setDetails] = useState('');
    const [isTracked, setIsTracked] = useState(false);
    const [todoLists, setTodoLists] = useState([]);

    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);

    // Fetch to-do lists from the server
    useEffect(() => {
        const fetchTodoLists = async () => {
            try {
                const response = await axios.get('https://basecamp-admin.webnexs.org/api/todolists');
                setTodoLists(response.data);
            } catch (error) {
                console.error('Error fetching to-do lists:', error);
            }
        };
        fetchTodoLists();
    }, []);

    // Add a new to-do list
    const handleAddList = async () => {
        const newList = { title: listName, description: details, isTracked: isTracked };

        try {
            const response = await axios.post('https://basecamp-admin.webnexs.org/api/todolists', newList);
            setTodoLists([...todoLists, response.data]);
            setListName('');
            setDetails('');
            setIsTracked(false);
            setShowTodoForm(false);
        } catch (error) {
            console.error('Error creating to-do list:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div className="campfire">
            <div className="text-center d-block m-auto campfire card--head camp" style={{ width: '65vw', height: '6vh' }}>
                <div className="d-flex justify-content-center align-items-center mt-2">
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className="me-1" icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <Link to="/project" className="fw-bold">Webnexs HQ</Link>
                </div>
            </div>

            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card text-center" style={{ width: '75vw' }}>
                    <div className="card bg-dark campfireBody">
                        <div className="container">
                            <div className="d-flex justify-content-between">
                                <button className="newEvent px-1 mt-3 py-1 text-dark" onClick={() => setShowTodoForm(true)}>
                                    <FaPlus className='me-1' /> New list
                                </button>
                                <div className="text-center text-light mt-3">
                                    <h3 className="mb-0 text-light">To-dos</h3>
                                    <RiPieChartFill className='text-success ms-2 fs-3' /> 0/4
                                </div>
                                <div className="text-end">
                                    <button className="Notifybtn text-light" onClick={openNav}>
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </button>
                                </div>
                            </div>
                            <hr className="line" />
                        </div>

                        {showTodoForm && (
                            <div className="text-center">
                                <div className="todocontainer">
                                    <input
                                        type="text"
                                        placeholder="Name this list..."
                                        value={listName}
                                        onChange={(e) => setListName(e.target.value)}
                                        className="todoinput"
                                    />
                                    <textarea
                                        placeholder="Add extra details or attach a file..."
                                        value={details}
                                        onChange={(e) => setDetails(e.target.value)}
                                        className="todotextarea"
                                    />
                                    <div className="todoactions">
                                        <button onClick={handleAddList} className="todoadd-button me-4">Add this list</button>
                                        <button className="todocancel-button" onClick={() => setShowTodoForm(false)}>Cancel</button>
                                        <label className="todocheckbox-label">
                                            <input
                                                type="checkbox"
                                                checked={isTracked}
                                                onChange={(e) => setIsTracked(e.target.checked)}
                                                className="todocheckbox me-2"
                                            />
                                            Track this list on the Hill Chart
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="todo-list-container p-3 text-start text-light">
                            {todoLists.map((list, index) => (
                                <div key={index} className="todo-item">
                                    <h4 className="fw-bold">{list.title}</h4>
                                    <p>{list.description}</p>
                                    <div className="todo-tasks">
                                        {/* Render tasks associated with each list */}
                                        <div className="todo-task">
                                            <input type="checkbox" className="todocheckbox task-checkbox" />
                                            <span className="task-text">Sample Task</span>
                                        </div>
                                    </div>
                                    <button className="add-task-btn">Add a to-do</button>
                                </div>
                            ))}
                        </div>
                        {/* Sidebar for settings */}
                        <div id="mySidenav" className={`CampfireSidenav messageboardSidenav ${isOpen ? 'open' : ''}`}>
                            <span className="closebtn" onClick={closeNav}>&times;</span>
                            <div className="P-2">
                                <Link to="#" className='sideBar text-decoration-none'>
                                    <button className='me-2 sidebarBtn'><FaPlus /> Bookmark</button>
                                </Link>
                            </div>
                            <div className="P-2">
                                <Link to="#" className='sideBar text-decoration-none'>
                                    <button className='me-2 sidebarBtn'><FaPlus /> Get a public link</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ToDos;
