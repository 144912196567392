import { useEffect, useState } from "react";

const MessageBoardShow = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/messages`);
        if (!response.ok) {
          throw new Error('Failed to fetch messages');
        }
        const data = await response.json();
        setMessages(data);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, []);

  return (
    <div className="cardbox">
      <h2 className="card-title text-white fw-bold fs-5 text-center py-2">Message Board</h2>
      <div className="card-body px-3 pt-2 overflow-hidden project-in-card">
        {messages.map((message) => (
          <div className="card-item pb-1" key={message._id}>
            <div className="item-header d-flex">
              <img alt="profilePic" src={message.profilePic} className="profile-pic" />
              <strong className="item-title pt-1 ps-2">{message.title}</strong>

            </div>
            <p className="item-paragraph text-white">
              {message.content ? `${message.content.substring(0, 35)}...` : 'No content available'}
            </p>
            <hr className="textLine m-0" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default MessageBoardShow;
