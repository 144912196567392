import React from 'react'
import { LuKey } from "react-icons/lu";
import { Link} from 'react-router-dom';
import { Form } from 'react-bootstrap';

const Two_Factor_Authentication = () => {
  return (
    <div className='Two_Factor_Authentication'>
    <div className=' text-center d-block m-auto card--head' style={{ width: '65vw', height: '6vh' }}>
        <div className='d-flex justify-content-center align-items-center mt-2'>
            <Link to='/View_Template' className='m-2 fw-bold'><LuKey /> Adminland</Link>
        </div>
    </div>
    <div className="d-flex justify-content-center align-items-center">
        <div className="body-bottom text-center" style={{ width: '75vw' }}>
                <div className="container p-3">
                    <div className="">
                        <p className='h2 fw-bold pt-3'>Require two-factor authentication</p>
                    </div>
                    <hr />
                    <div className='body-content w-75 mx-auto'>
                        <div className=' text-center'>
                        <p className='text-start'>Upgrade your security by requiring two-factor authentication (2FA) to log in to Basecamp.</p>
                        <p className='text-start bgclr p-3'><strong>Step 1:</strong> <Link to=''>Set up 2FA for your own login</Link> </p>
                        <p className='text-start p-3 '><strong>Step 2:</strong> Enforce 2FA in the my own comp Basecamp account</p>
                      </div>
                     
                    </div>
                </div>
       </div>
    </div>
</div>
  )
}

export default Two_Factor_Authentication