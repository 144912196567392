import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate(); // Correct usage of useNavigate
    const [password, setPassword] = useState('');
    // const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`https://basecamp-admin.webnexs.org/api/reset-password/${token}`, { password });
             toast.success('Password has been reset')
            setTimeout(() => {
                navigate('/login');
            }, 3333);
        } catch (error) {
            // setMessage(error.response.data.message);
            toast.error('Failed to perform action');
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center Forgot">
            <div className='Loginpage text-center' style={{ maxWidth: '448px', width: '100%' }}>
                <div className="card p-3 mt-4 shadow-sm">
                    <div className="card-body">
                        <h2 className="h5 mt-0 mb-2 text-center fw-bold">Reset your Password</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control mb-2"
                                    name="password"
                                    placeholder="Enter your new password..."
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-success btn-block mt-3 w-100 fw-bold">
                                Reset Password
                            </button>
                        </form>
                        {/* {message && <p className="mt-3 text-center">{message}</p>} */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
