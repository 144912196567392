import React from 'react'
import { MdEmail } from "react-icons/md";
const EmailForwardShow = () => {
    return (
        <div className="cardbox">
            <h2 className="card-title text-white fw-bold fs-5 text-center py-2">
                Email Forward
            </h2>
            <div className="card-body px-3 pt-2 overflow-hidden project-in-card">
                <div className="card-item">
                    <div className="d-flex justify-content-center pt-3">
                        <div className="email-icon">
                            <MdEmail style={{ fontSize: 50 }} />
                        </div>
                    </div>
                    <p className="fw-normal fs-6 text-secondary text-center">Share email with your team, collect comments, and reply directly to the original sender.</p>
                </div>
            </div>
        </div>
    )
}

export default EmailForwardShow
