import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include the styles for the editor
import EmojiPicker from 'emoji-picker-react';
import { FaA } from "react-icons/fa6";
import { BsEmojiSmile } from "react-icons/bs";
import { IoIosAttach } from "react-icons/io";

const PrivateChatinPing = () => {
    const [editorVisible, setEditorVisible] = useState(false);
    const [editorValue, setEditorValue] = useState(''); // State to store editor content
    const [showEmojiPicker, setShowEmojiPicker] = useState(false); // Emoji picker visibility
    const [attachment, setAttachment] = useState(null); // File attachment
    const [inputValue, setInputValue] = useState(''); // State to store input field content
    const emojiPickerRef = useRef(null); // Ref for the emoji picker

    // Quill toolbar configuration for formatting options
    const toolbarOptions = [
        ['bold', 'italic', 'strike'], // Formatting options
        [{ list: 'ordered' }, { list: 'bullet' }], // Ordered and unordered lists
        ['link'], // Link option
        [{ 'color': [] }, { 'background': [] }],
        ['blockquote', 'code-block'],
        ['clean']
    ];

    // Handle emoji click
    const handleEmojiClick = (emojiData) => {
        setInputValue(inputValue + emojiData.emoji); // Append emoji to input field
    };

    // Handle file change
    const handleFileChange = (event) => {
        setAttachment(event.target.files[0]);
    };

    // Handle clicks outside of the emoji picker
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
                setShowEmojiPicker(false);
            }
        };

        // Add event listener for clicks outside
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="chat-container">
            <div className="chat-header"></div>
            <div className='pingMsg'>
                <div style={{ margin: '0 auto' }} className='px-3 py-0'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', background: '#2c3e50', paddingRight: "10px", borderRadius: '5px', border: '2px solid #32526c' }}>

                        {editorVisible ? (
                            // Show the editor if editorVisible is true
                            <ReactQuill
                                value={editorValue}
                                onChange={setEditorValue} // Update the state with new content
                                modules={{ toolbar: toolbarOptions }}
                                style={{ flexGrow: 1, minHeight: '100px' }} // Flex to take up input space
                            />
                        ) : (
                            // Show the regular input field when editorVisible is false
                            <input
                                type="text"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                placeholder="Type something..."
                                style={{
                                    flexGrow: 1,
                                    padding: '10px',
                                    border: 'none',
                                    background: 'transparent',
                                    color: 'white',
                                    outline: 'none',
                                    fontSize: '16px',
                                }}
                            />
                        )}

                        <button
                            onClick={() => {
                                setEditorVisible(!editorVisible);
                                if (!editorVisible) {
                                    setEditorValue(inputValue); // Transfer input text to editor
                                } else {
                                    setInputValue(editorValue); // Transfer editor content back to input
                                }
                            }}
                            style={{ background: 'transparent', color: '#fff', border: 'none', fontSize: '16px', cursor: 'pointer' }}
                        >
                            <FaA className='editorIcon' />
                        </button>

                        <button onClick={() => setShowEmojiPicker(!showEmojiPicker)} className='emojiBtn'>
                            <BsEmojiSmile className='emojiIcon' />
                        </button>

                        <input type="file" onChange={handleFileChange} style={{ display: 'none' }} id="file-upload" />
                        <label htmlFor="file-upload" style={{ cursor: 'pointer', fontSize: "larger" }} className='m-0'>
                            <IoIosAttach />
                        </label>
                    </div>

                    {/* Emoji Picker */}
                    {showEmojiPicker && (
                        <div ref={emojiPickerRef} style={{ position: 'absolute', zIndex: 1000 }}>
                            <EmojiPicker onEmojiClick={handleEmojiClick} />
                        </div>
                    )}

                    {/* Display attachment if uploaded */}
                    {attachment && (
                        <div style={{ marginTop: '10px', color: '#fff' }}>
                            <strong>Attachment:</strong> {attachment.name}
                        </div>
                    )}
                </div>
            </div>


        </div >
    );
};

export default PrivateChatinPing;
