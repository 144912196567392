import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faBold, faItalic, faStrikethrough, faLink, faFillDrip, faT,
  faQuoteLeft, faAlignCenter, faCode, faListUl, faListOl, faRotateRight, faRotateLeft
} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Toast } from 'react-bootstrap';
import toast from 'react-hot-toast';

const Coworkers_Account = () => {
  const location = useLocation();
  const userDataFromState = location.state?.userData || null;
  const storedUserData = localStorage.getItem('userData');
  const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
  const [isLoading, setIsLoading] = useState(false);
  const [isTextEditorVisible, setTextEditorVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    jobTitle: '',
    company: userData.user.companyname,
    personalNote: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTextEditLinkClick = (e) => {
    e.preventDefault();
    setTextEditorVisible(true);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name?.trim()) newErrors.name = 'Name is required';

    if (!formData.email?.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[\w.%+-]+@[\w.-]+\.(com|net|org|edu|in)$/i.test(formData.email)) {
      newErrors.email = 'Email must be a valid address';
    }

    if (!formData.company?.trim()) newErrors.company = 'Company is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/invite`, formData);
      if (response.status === 200) {
        // alert('Invitation sent successfully!');
        toast.success('Invitation sent successfully!')
        setFormData({
          name: '',
          email: '',
          jobTitle: '',
          company: userData.user.companyname,
          personalNote: ''
        });
        setErrors({});
      }
    } catch (error) {
      console.error('Error sending invitation:', error);
      alert('There was an error sending the invitation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='coworkersAccount'>
      <div className='Coworkers_Account p-3'>
        {/* Header */}
        <div className='d-flex justify-content-center align-item-center mt-5'>
          <h2 className='fw-bold text-white text-center'>Set up your coworker’s account</h2>
        </div>
        <div className='d-flex justify-content-center align-item-center'>
          <p className='text-white text-center w-75'>
            People who work at {userData.user.companyname} are the only people who can create projects, add others
            to projects, and act as administrators. They can be full-time, part-time, or a volunteer.
          </p>
        </div>

        {/* Form */}
        <div className="container d-flex justify-content-center align-items-center">
          <form className="form-box p-4 mt-3 rounded" onSubmit={handleSubmit}>
            <div className="row mb-3 mt-3">
              <div className="col">
                <input
                  type="text"
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  placeholder={errors.name || "Full name"}
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col">
                <input
                  type="email"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  placeholder={errors.email || "Email address"}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job title (optional)"
                  name="jobTitle"
                  value={formData.jobTitle}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className={`form-control ${errors.company ? 'is-invalid' : ''}`}
                  placeholder={errors.company || "Company/organization"}
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="d-flex justify-content-center">
                <button type="plusbutton" className="btn btn-outline-success rounded-circle add-button">
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>
          </form>
        </div>

        {!isTextEditorVisible && (
          <div className='d-flex justify-content-center align-item-center mt-5'>
            <Link to='' className='downlink text-edit-link' onClick={handleTextEditLinkClick}>
              Add a personal note to the invitation email
            </Link>
          </div>
        )}

        {isTextEditorVisible && (
          <div className='container d-flex justify-content-center align-items-center'>
            <div className='text-editor p-3 mt-5'>
              <p className='fw-bold pt-2'>Add a personal note to the invitation email</p>
              <div className='text-box'>
                <div className='d-flex justify-content-between'>
                  <div className='left-icons'>
                    <button><FontAwesomeIcon icon={faBold} /></button>
                    <button><FontAwesomeIcon icon={faItalic} /></button>
                    <button><FontAwesomeIcon icon={faStrikethrough} /></button>
                    <button><FontAwesomeIcon icon={faLink} /></button>
                    <button><FontAwesomeIcon icon={faFillDrip} /></button>
                    <button><FontAwesomeIcon icon={faT} /></button>
                    <button><FontAwesomeIcon icon={faQuoteLeft} /></button>
                    <button><FontAwesomeIcon icon={faAlignCenter} /></button>
                    <button><FontAwesomeIcon icon={faCode} /></button>
                    <button><FontAwesomeIcon icon={faListUl} /></button>
                    <button><FontAwesomeIcon icon={faListOl} /></button>
                  </div>
                  <div className='right-icons'>
                    <button><FontAwesomeIcon icon={faRotateLeft} /></button>
                    <button><FontAwesomeIcon icon={faRotateRight} /></button>
                  </div>
                </div>
                <textarea
                  name="personalNote"
                  className='w-100 text-area'
                  rows={7}
                  placeholder='Type your note here...'
                  value={formData.personalNote}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>
        )}

        <div className='d-flex justify-content-center'>
          <button className='mt-3 next-btn' onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? 'Sending...' : 'Email invitation now...'}
          </button>
        </div>

        <div className='d-flex justify-content-center align-item-center mt-4'>
          <p className='downlink'>
            Need to add several coworkers at once?{' '}
            <Link to='/invite-link' className='downlink'>
              Add a personal note to the invitation email
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Coworkers_Account;