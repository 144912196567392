
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const AddPeopleToProject = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isEditorActive, setIsEditorActive] = useState(false); // State to track if editor is active

    const handleFocus = () => {
        setIsEditorActive(true); // Activate editor on focus
    };

    return (
        <div className='addPeopleContainer'>
            <div className='addPeopleSubContainer'>
                <Link to="/setup-people" className="fw-bold">Back to People on Webnexs</Link>
                <div className='my-3'>
                    <h1 className='fw-bold' style={{ color: 'white' }}>Add people to this project</h1>
                </div>
                <div className='p-5 bg-dark rounded-4'>
                    {/* Input field for adding people */}
                    <div className="mb-3">
                        <label htmlFor="addPeopleInput" className="fw-bold " style={{ color: 'white' }}>
                            Who do you want to add to this project?
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="addPeopleInput"
                            placeholder="Type their names here..."
                            style={{ color: 'white' }}
                        />
                        <Link to="" className="text-info">
                            Or pick people from a company...
                        </Link>
                    </div>

                    {/* Conditionally render either input or editor */}
                    <div className="mb-3">
                        <label htmlFor="invitationNote" className="fw-bold " style={{ color: 'white' }}>
                            Add an optional note to the invitation email
                        </label>
                        <div

                        >
                            {isEditorActive ? (
                                <Editor
                                    className="textAreaEditor"
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                    toolbar={{
                                        options: ['inline', 'list', 'link', 'history'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                                        },
                                        list: { options: ['unordered', 'ordered'] },
                                        link: { options: ['link'] },
                                    }}
                                    editorStyle={{ color: 'white', backgroundColor: '#2e3b4e' }}
                                    toolbarStyle={{ backgroundColor: '#333', border: 'none' }}
                                />
                            ) : (
                                <input
                                    type="text"
                                    className="form-control "
                                    id='textAreaEditor'
                                    placeholder="Add an optional note here..."
                                    style={{ color: 'white', backgroundColor: '#2e3b4e', border: '1px solid' }}
                                    onFocus={handleFocus}
                                />
                            )}
                        </div>
                    </div>

                    {/* Instructions for what happens next */}
                    <div className="mt-3 text-muted">
                        <p style={{ color: 'white' }} className='text-center'>
                            What happens next? Everyone above will be immediately added to the project and receive an email with instructions to join. They'll be able to see everything posted so far.
                        </p>
                    </div>

                    {/* Button to add people */}
                    <div className="text-center">
                        <button className="btn  border rounded-pill border-dark py-2 addProjectPeopleBtn">
                            Add them to the project
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPeopleToProject;
