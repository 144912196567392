import React from 'react'
import { Link } from 'react-router-dom'
import { LuKey } from "react-icons/lu";
import { FaPlus } from "react-icons/fa";

const ManageGroups = () => {

    const videoUrl = 'https://bc3-production-assets-cdn.basecamp-static.com/assets/blank_slates/how-to-use-groups-1feaa0ed00b6cef32cce011a830de38629be91b8a97c0258cb2867dcfd879d44.gif';


  return (
    <div className='ManageGroups'>
            <div className=' text-center d-block m-auto card--head' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <Link to='/adminland' className='m-2 fw-bold'><LuKey /> Adminland</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="body-bottom text-center" style={{ width: '75vw' }}>
                        <div className="container p-3">
                            <div className="">
                                <p className='h2 fw-bold pt-3'>Manage groups</p>
                            </div>
                            <hr />
                            <div className='body-content'>
                              <p className='px-5 pt-3 text-start'>Groups let you mention or assign multiple people at once. You can add a group to a project or event, assign them a to-do, or @mention them in Chat. When you choose the group, it automatically expands into its individual members.</p>
                              <p className='px-5 py-2 text-start text-secondary'>Here’s an example of how you can easily involve groups on your projects:</p>
                              <img src={videoUrl} className='img-fluid mb-3' alt="Manage groups in teamnex" />
                              <hr className='mx-5' />
                              <button className='p-2 bttn mt-3'><span><FaPlus /></span> Make a new group</button>
                            </div>
                        </div>
               </div>
            </div>
    </div>
  )
}

export default ManageGroups