import React from 'react'

const FAQSection = () => {
  return (
    <div className="faq-section">
    <h2>Questions? Answers.</h2>
    <div className="faq-item">
      <h3>How does the annual payment option work?</h3>
      <p>We bill you once a year in full for $708 ($59/month x 12 months which is a 10% discount off the regular $69/month price if you paid monthly). The plan automatically renews every year on your annual billing date, but you can cancel any time. <a href="#">Our fair refund policy</a> covers all the relevant details. You can even get an export of all your data to keep forever for reference.</p>
    </div>
    <div className="faq-item">
      <h3>Is there any limit to the number of projects we can have?</h3>
      <p>Nope! You can have as many projects on Basecamp as you’d like. There’s never an additional charge for doing more work in Basecamp.</p>
    </div>
    <div className="faq-item">
      <h3>Do I have to install anything?</h3>
      <p>Nope! You can do all your Basecamping through your web browser. That said, if you’d prefer, we do offer<a href='#'> Mac, Windows, iOS, and Android apps</a> for you to use too.</p>
    </div>
    <div className="faq-item">
      <h3>Do you have discounts for non-profits and education?</h3>
      <p>We sure do! Basecamp is free for students and teachers, and 10% off for non-profits and charities. To get the discounted rate, just <a href="#">contact our support team</a>. They’ll ask you for some documentation verifying that you’re eligible, and then they’ll apply the discount to your account.</p>
    </div>
    <div className="faq-item">
      <h3>What if I change my mind?</h3>
      <p>No problem, you can cancel anytime, all on your own, no questions asked. You can even get an export of all your data to keep forever for reference. If you’re curious, you can read <a href="#">our fair refund policy</a>.</p>
    </div>
  </div>
  )
}

export default FAQSection
