import React from 'react'
import { Link } from 'react-router-dom'
import { LuKey } from "react-icons/lu";

const ManageCompanies = () => {
  return (
    <div className='ManageCompanies'>
    <div className=' text-center d-block m-auto card--head' style={{ width: '65vw', height: '6vh' }}>
        <div className='d-flex justify-content-center align-items-center mt-2'>
            <Link to='/adminland' className='m-2 fw-bold'><LuKey /> Adminland</Link>
        </div>
    </div>
    <div className="d-flex justify-content-center align-items-center">
        <div className="body-bottom text-center" style={{ width: '75vw' }}>
                <div className="container p-3">
                    <div className="">
                        <p className='h2 fw-bold pt-3'>Manage Companies</p>
                    </div>
                    <hr />
                    <div className='body-content'>
                        <div class="content-between-lines">
                           <hr class="linee" />
                           <span class="textt">This can’t be deleted because it’s your account’s primary company</span>
                            <hr class="linee" />
                        </div>
                      <div className='d-flex justify-content-between mx-5 mt-1'>
                        <div>
                            <strong>my own comp</strong> <br /> <p>5 people (show them)</p>
                        </div>
                        <div>
                            <button className='p-1 round-bttn'>Edit</button>
                        </div>
                      </div>
                    </div>
                </div>
       </div>
    </div>
</div>
  )
}

export default ManageCompanies