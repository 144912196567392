import React from 'react'

const PlanCard = ({ planTitle, planSubtitle, price, billingDetails, features, includedAddOns }) => {
    return (
      <div className="plan-card">
        <h2 className='text-center'>{planTitle} <span>{planSubtitle}</span></h2>
        <div className="billing-options text-center">
          <button className="active planbtn">Pay Annually</button>
          <button className='planbtn'>Pay Monthly</button>
        </div>
        <div className="price text-center">
          <h3>{price}</h3>
          <p>{billingDetails}</p>
        </div>
        <button className="choose-plan-btn">Choose this plan</button>
        <ul className="feature-list">
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        <div className="addons-container">
      <h2>Level up ✨ with add-ons</h2>
      <div className="addons">
        <div className="addon">
          <h3>Timesheet</h3>
          <span>Included in your trial</span>
          <p>Give your team the power to track time spent on projects.</p>
        </div>
        <div className="addon">
          <h3>Admin Pro Pack</h3>
          <span>Included in your trial</span>
          <p>Greater control over access and permissions.</p>
        </div>
        <div className="addon">
          <h3>Storage</h3>
          <span>Available on paid plans</span>
          <p>Load up on file and document storage.</p>
        </div>
      </div>
      <div className="addons-footer">
        <p className='fw-bold'>No matter which plan you choose, you get the same great Basecamp:</p>
        <ul>
          <li>Unlimited projects</li>
          <li>Loads of storage space</li>
          <li>Invite guests for free</li>
          <li><a href='#'>Every feature you need</a></li>
          <li>Innovative<a href='#'> client access</a></li>
          <li><a href='#'>Rock-solid uptime reliability</a></li>
          <li>24/7/365 <a href='#'>top-notch support</a></li>
          <li>Complimentary<a href='#'> classes</a></li>
        </ul>
      </div>
    </div>
      </div>
    );
  };
  

export default PlanCard
