// import React, { useState } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import toast from 'react-hot-toast';
// const ResetPassword = () => {
//     const { token } = useParams();
//     const navigate = useNavigate(); // Correct usage of useNavigate
//     const [password, setPassword] = useState('');
//     // const [message, setMessage] = useState('');

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password/${token}`, { password });
//             toast.success('Password has been reset')
//             setTimeout(() => {
//                 navigate('/login');
//             }, 3333);
//         } catch (error) {
//             // setMessage(error.response.data.message);
//             toast.error('Failed to perform action');
//         }
//     };

//     return (
//         <div className="d-flex justify-content-center align-items-center Forgot">
//             <div className='Loginpage text-center' style={{ maxWidth: '448px', width: '100%' }}>
//                 <img src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public' alt="Logo" className="d-block m-auto" style={{ width: "100px" }} />

//                 <div className="card p-3 mt-4 shadow-sm text-white">
//                     <div className="card-body">
//                         <h2 className="h5 mt-0 mb-2 text-center fw-bold">Reset your Password</h2>
//                         <form onSubmit={handleSubmit}>
//                             <div className="form-group">
//                                 <input
//                                     type="password"
//                                     className="form-control mb-2"
//                                     name="password"
//                                     placeholder="Enter your new password..."
//                                     value={password}
//                                     onChange={(e) => setPassword(e.target.value)}
//                                     required
//                                 />
//                             </div>
//                             <button type="submit" className="loginBtn mt-3 w-100 fw-bold">
//                                 Reset Password
//                             </button>
//                         </form>
//                         {/* {message && <p className="mt-3 text-center">{message}</p>} */}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ResetPassword;

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password.length < 12) {
            setErrorMessage('Password must be at least 12 characters long.');
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage('Passwords does not match!');
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password/${token}`, { password });
            toast.success('Password has been reset');
            setTimeout(() => {
                navigate('/');
            }, 3333);
        } catch (error) {
            // setErrorMessage('Failed to perform action');
            toast.error('Failed to perform action');
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center Forgot">
            <div className='Loginpage text-center' style={{ maxWidth: '448px', width: '100%' }}>
                <img
                    src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public'
                    alt="Logo"
                    className="d-block m-auto"
                    style={{ width: "100px" }}
                />

                <div className="card p-3 mt-2 shadow-sm text-white">
                    <div className="card-body">
                        <h2 className="h5 mt-0 mb-3 text-center fw-bold">Reset your password</h2>

                        <form onSubmit={handleSubmit}>
                            <div className="form-group text-start">
                                <label htmlFor="newPassword" className="fw-bold mb-0">Enter a new password</label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    className="form-control mb-2"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            {errorMessage && <p className="text-danger text-start fw-light">{errorMessage}</p>}
                            <div className="form-group text-start">
                                <label htmlFor="confirmPassword" className="fw-bold mb-0">Confirm your password</label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    className="form-control mb-2"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="loginBtn mt-3 w-100 fw-bold">
                                Save my password
                            </button>
                            <hr></hr>
                        </form>

                        <div className="text-start mt-3">
                            <p className='mb-0 fw-bold'>Need help?</p>
                            <ul className='mb-0'>
                                <li className='fs-6'>
                                    <a href="" >Learn more about creating a strong password</a>

                                </li>
                                <li className='fs-6'>
                                    <a href="mailto:webnexs29@gmail.com?subject=Support%20Request&body=Hi%20there,">Contact our support team</a>

                                </li>
                            </ul>
                            <br />
                        </div>
                    </div>
                </div>
                <a href="/" className="mt-2 d-block fs-6">Never mind, go to the login screen</a>
            </div>
        </div >
    );
};

export default ResetPassword;
