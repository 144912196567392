import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';

const OutOfOffice = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
     <div className='container'>
      <div className='OutOfOffice text-center d-block m-auto mt-3'>
        <div className='header pt-4'>
          <h2 className='fw-bold'>Out of Office?</h2>
          <p>Heading out on holiday? Taking a few days off? <br />
            Let your colleagues know you’re out.
          </p>
        </div>
        <div className='box d-block m-auto'>
          <div className='photo d-block m-auto'>
            <span className='texthead mt-5'><b>AK</b></span>
          </div>
          <h2 className='mt-3 fw-bold text-dark'>Arun Kumar</h2>
          <p className='mt-2 text-muted'>Web Application Developer at webnexs.com</p>

          <div className='d-flex justify-content-center align-items-center'>
            <div className='d-flex align-items-center m-auto'>
              <h5 className='me-3 mt-1 text-dark'>I'm In</h5>
              <input type="checkbox" id="check" checked={isChecked} onChange={handleCheckboxChange} />
              <label htmlFor="check" className='button'></label>
              <h5 className='ms-3 mt-1 text-dark'>I'm Out</h5>
            </div>
            
          </div>

          {isChecked && (
            <div className='d-block m-auto bottom'>
              <hr />
              <h3 className='mt-3 fw-bold text-dark'>I’ll be Out of Office…</h3>
              <div className='d-flex justify-content-center align-items-center mt-4'>
                <button className='calendar-button p-1 me-3'>
                  Fri, Jun 28, 2024 <FontAwesomeIcon className='ms-2 me-1' icon={faCalendarDays} />
                </button>
                <span className='text-dark'>to</span>
                <button className='calendar-button p-1 ms-3'>
                  Fri, Jun 29, 2024 <FontAwesomeIcon className='ms-2 me-1' icon={faCalendarDays} />
                </button>
              </div>
              <button className='p-2 mt-3 w-75 save-button'>
                Save
              </button>

              <p className='mt-4 text-dark'>Note: It may take up to 30min for your avatar to update everywhere.</p>
            </div>
          )}
        </div>
      </div>
      <div className='OutOfOffice d-block m-auto mt-3 bg-transparent'>
        <h5 className='pt-3 fw-bold'>How will my colleagues know that I'm out?</h5>
        <p>There are several ways in which we flag that you are out, and when you are coming back:</p>
        <ul>
          <li><p> <strong>Person Card + Avatar:</strong> We place an "OUT" yellow overlay on your avatar across the entire TeamNex. Check it out by switching "I'm out" using the toggle above.</p></li>
          <li><p> <strong>Autocomplete:</strong> If someone types @yourname, the autocomplete will show that you are out and when you are coming back. </p></li>
          <li><p> <strong>Your activity:</strong> If someone visits your activity page, they will see when you are out and when you are coming back. </p></li>
        </ul>

        <h5 className='pt-3 fw-bold'>Do I get notifications while I'm Out?</h5>
        <p>Out of Office doesn't affect your notifications. To limit your own notifications, you can change your notifications from the <a className='text-white' href="">Notifications Settings screen</a> </p>

        <h5 className='pt-3 fw-bold'>Is there an auto-responder while out?</h5>
        <p>Out of Office is NOT an auto-responder. OOO is a visual way to communicate to other people that you're out, and when you'll be back.</p>
      </div>

     </div>
  );
};

export default OutOfOffice;
