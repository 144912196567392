import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faBold,faItalic,faStrikethrough,faLink,faFillDrip,faT,faQuoteLeft,faAlignCenter,faCode,faListUl,faListOl, faRotateRight,faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios'; // Import Axios for making API requests

const Coworkers_Account = ({ userData }) => {
  const location = useLocation();
  const currentUserData = location.state?.userData || userData;

  const [isTextEditorVisible, setTextEditorVisible] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    jobTitle: '',
    company: currentUserData || '',
    personalNote: ''
  });

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setFormData({ ...formData, [name]: value });
  };

  const handleTextEditLinkClick = (e) => {
    e.preventDefault();
    setTextEditorVisible(true);
  };

  // Function to handle form submission and API request
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://basecamp-admin.webnexs.org/api/invite', formData);
      if (response.status === 200) {
        alert('Invitation sent successfully!');
        // Reset form
        setFormData({
          name: '',
          email: '',
          jobTitle: '',
          company: currentUserData || '',
          personalNote: ''
        });
      }
    } catch (error) {
      console.error('Error sending invitation:', error);
      alert('There was an error sending the invitation. Please try again.');
    }
  };

  return (
    <div className='Coworkers_Account p-3'>
      <div className='d-flex justify-content-center align-item-center mt-5'>
        <h3 className='fw-bold text-white text-center'>Set up your coworker’s account</h3>
      </div>
      <div className='d-flex justify-content-center align-item-center'>
        <h5 className='text-white text-center w-75'>
          People who work at {currentUserData} are the only people who can create projects, add others
          to projects, and act as administrators. They can be full-time, part-time, or a volunteer.
        </h5>
      </div>

      <div className="container d-flex justify-content-center align-items-center">
        <form className="form-box p-4 mt-3 rounded" onSubmit={handleSubmit}>
          <div className="row mb-3 mt-3">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Full name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col">
              <input
                type="email"
                className="form-control"
                placeholder="Email address"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Job title (optional)"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Company/organization"
                name="company"
                value={currentUserData} 
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            {/* Submit button here */}
            <button type="submit" className="btn btn-outline-success rounded-circle add-button">
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </form>
      </div>

      {!isTextEditorVisible && (
        <div className='d-flex justify-content-center align-item-center mt-4'>
          <Link to='' className='downlink text-edit-link' onClick={handleTextEditLinkClick}>
            Add a personal note to the invitation email
          </Link>
        </div>
      )}

      {isTextEditorVisible && (
        <div className='container d-flex justify-content-center align-items-center'>
          <div className='text-editor p-3 mt-5'>
            <p className='fw-bold pt-2'>Add a personal note to the invitation email</p>
            <div className='text-box'>
              <div className='d-flex justify-content-between'>
                <div className='left-icons'>
                  <button><FontAwesomeIcon icon={faBold} /></button>
                  <button><FontAwesomeIcon icon={faItalic} /></button>
                  <button><FontAwesomeIcon icon={faStrikethrough} /></button>
                  <button><FontAwesomeIcon icon={faLink} /></button>
                  <button><FontAwesomeIcon icon={faFillDrip} /></button>
                  <button><FontAwesomeIcon icon={faT} /></button>
                  <button><FontAwesomeIcon icon={faQuoteLeft} /></button>
                  <button><FontAwesomeIcon icon={faAlignCenter} /></button>
                  <button><FontAwesomeIcon icon={faCode} /></button>
                  <button><FontAwesomeIcon icon={faListUl} /></button>
                  <button><FontAwesomeIcon icon={faListOl} /></button>
                </div>
                <div className='right-icons'>
                  <button><FontAwesomeIcon icon={faRotateLeft} /></button>
                  <button><FontAwesomeIcon icon={faRotateRight} /></button>
                </div>
              </div>
              <textarea
                name="personalNote"
                className='w-100 text-area'
                rows={7}
                placeholder='Type your note here...'
                value={formData.personalNote}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
        </div>
      )}

      <div className='d-flex justify-content-center'>
        <button className='mt-3 next-btn' onClick={handleSubmit}>
          Email invitation now...
        </button>
      </div>

      <div className='d-flex justify-content-center align-item-center mt-4'>
        <p className='downlink'>
          Need to add several coworkers at once?{' '}
          <Link to='/Invite_Link' className='downlink'>
            Add a personal note to the invitation email
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Coworkers_Account;
