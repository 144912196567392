import React ,{ useRef, useState }from 'react'
import { LuKey } from "react-icons/lu";
import { Link} from 'react-router-dom';

const Add_Remove_Acc = () => {

  const name = 'NewName';
  const firstLetter = name.charAt(0).toUpperCase();

  const [selectedEmp, setSelectedEmp] = useState('');

    const People = [
        { value: '1', label: 'Choose a person...' },
        { value: 'Arun', label: 'ArunKumar' },
        { value: 'Ganesh', label: 'Ganesh' },
        { value: 'Pragatheeshwaran', label: 'Pragatheeshwaran' },
        
    ];
    const handleChange = (event) => {
        setSelectedEmp(event.target.value);
    };

  return (
    
    <div className='Add_Remove_Acc'>
    <div className=' text-center d-block m-auto card--head' style={{ width: '65vw', height: '6vh' }}>
        <div className='d-flex justify-content-center align-items-center mt-2'>
            <Link to='/adminland' className='m-2 fw-bold'><LuKey /> Adminland</Link>
        </div>
    </div>
    <div className="d-flex justify-content-center align-items-center">
        <div className="body-bottom text-center" style={{ width: '75vw' }}>
                <div className="container p-3">
                    <div className="">
                        <p className='h2 fw-bold pt-3'>Add/Remove Account Owners</p>
                    </div>
                    <hr />
                    <div className='body-content w-75 mx-auto'>
                        <div className=' text-center'>
                        <p className='text-start'>Account owners have authority over this Basecamp account. They can do things like cancel the account, upgrade and downgrade, export data, and view everything on the account. It's a good idea to add another owner so they can handle any matters that might arise with your account in case you can't be reached.</p>

                        <hr className='lin' />
                         <div className="d-flex align-items-center">
                         <div className="rounded-circle d-flex justify-content-center align-items-center"
                         style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: '#A35CEB',
                            color: 'white',
                            fontSize: '24px',
                            fontWeight: 'bold',
                            margin: '5px'
                            }}
                        >
                         {firstLetter}
                     </div>
                        <span className="text-white fw-bold" >{name}</span>
                        </div>
                        <hr className='lin' />

                        <p className='fw-bold text-start mb-0'>Who else should be an account owner?</p>

                        <select
                            className='px-2 proinput text-light w-100 mt-0'
                            id="name-selector"
                            value={selectedEmp}
                            onChange={handleChange}
                        >
                            {People.map((name) => (
                                <option key={name.value} value={name.value}>
                                    {name.label}
                                </option>
                            ))}
                        </select>
                        
                        <div className='d-flex text-start'>
                            <button className='p-2 mt-3 mx-1 bttn-1'>Grant Account owners power</button>
                        </div>
                      </div>
                     
                    </div>
                </div>
       </div>
    </div>
</div>
  )
}

export default Add_Remove_Acc