import React from 'react'
import App from "../asset/App Store.png"
import Play from "../asset/Google Play.png"


const MyDevices = () => {
  return (
    <div className='container MyDevice text-center d-block m-auto mt-3 w-75 p-3'>
      <div className='header pt-3'>
        <div className='text-center circle d-block m-auto  rounded-circle  text-white p-3 mt-3 h3'>
          PJ
        </div>
        <h1>Get TeamNex on your mobile device</h1>
        <hr />
      </div>
      <div className='Device-Body'>
        <p >TeamNex works great on the go! Download our free apps for iOS and Android to get notifications about what’s <br />  happening in your TeamNex wherever you are.</p>
        <div className='d-flex justify-content-center '>
          <a className='App-Store me-2 ' href="appstore"><img className='img-fluid' src={App} alt="" width="130" /></a>
          <a className='Play-Store ' href="playstore"><img className='img-fluid' src={Play} alt="" width="130" /></a>
        </div>
        <div className='mt-5 ps-5 text-start'>
          <p>
            <b>If your device isn’t listed you’ll need to </b>
            <b><a href="#">enable notifications</a></b>
          </p>
          <ul>
            <li><a href="#">Install the Android app</a> then <a href="#">enable notifications</a>.</li>
            <li><a href="#">Install the iOS app</a> then <a href="#">enable notifications</a>.</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MyDevices