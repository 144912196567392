import React, { useState } from 'react'
import { RiKey2Fill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const Changecontent = () => {

    const [selectedOption, setSelectedOption] = useState('anyone');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    return (
        <div className='campfire'>
            <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <Link to="/adminland" className="fw-bold"><RiKey2Fill style={{
                        fontSize: "20"
                    }} />Adminland</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card " style={{ width: '75vw' }}>
                    <div className="card bg-dark campfireBody">
                        <div className="container ">
                            <div className=" text-center mt-3">
                                <a href="archive" className='headcamp fw-bold'> <h3 className="mb-0 text-light">Change who can archive and delete content</h3> </a>
                            </div>
                            <hr className="line" />
                        </div>
                        <div className="card-body bg-dark p-0" style={{ height: '80vh', }}>
                            <div className="admin-panel text-light">
                                <div className="admin-list">
                                    <p>By default, anyone who is on a project can archive and delete the project and anything in it,
                                        like messages, docs, and to-dos, whether or not they added the content.</p>
                                    <p>Here you can change that setting so that only the person who adds something,
                                        plus admins and owners, can archive and delete it.</p>
                                </div>
                                <h6 className='mt-3'>Who can archive and delete content</h6>
                                <div className="add-admin">
                                    <div className="">
                                        <div className="options">
                                            <div className='labelInput text-start'>
                                                <input
                                                    className='inputRadio '
                                                    type="radio"
                                                    value="anyone"
                                                    checked={selectedOption === 'anyone'}
                                                    onChange={handleOptionChange}
                                                />
                                                Anyone on the project
                                            </div>
                                            <div className='labelInput '>
                                                <input
                                                    className='inputRadio'
                                                    type="radio"
                                                    value="admins"
                                                    checked={selectedOption === 'admins'}
                                                    onChange={handleOptionChange}
                                                />
                                                Only admins and owners and the person who created the content
                                            </div>
                                        </div>
                                        <div className="buttons">
                                            <button className="changebtn save-button">Save changes</button>
                                            <button className="changebtn cancel-button">Never mind</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Changecontent;
