import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight,faNoteSticky,faCalendarWeek } from '@fortawesome/free-solid-svg-icons';

const Overdue = () => {
  return (
    <div className='Overdue container p-3 w-75'>
        <div className='d-flex justify-content-center align-item-center fw-bold h2 pt-2'>
            <p className='heading'>Overdue to-dos</p>
        </div>
        <hr />

        <div className='contents ps-5 mb-5'>
            <div className="one ps-5">
                <h4 className='heading'>42 days overdue</h4>
                <p>Webnexs Email Newsletter in webnexs HQ</p>
                <div className='task pt-0 d-flex'>
                      <input type='checkbox' /> 
                      <span className='title'>Need Ecommerce Email Newsletter Design - 13</span> 
                      <button className='notes'><FontAwesomeIcon icon={faNoteSticky} /></button>
                      <button className='notes text-warning'><FontAwesomeIcon icon={faCalendarWeek} /></button>
                      <span>Wed, May 29</span>
                </div>
            </div>
        </div>
        <div className='contents ps-5 mb-5'>
            <div className="one ps-5">
                <h4 className='heading'>43 days overdue</h4>
                <p>Webnexs Email Newsletter in webnexs HQ</p>
                <div className='task pt-0 d-flex'>
                      <input type='checkbox' /> 
                      <span className='title'>Need Email Newsletter Design - 24/05/24</span> 
                      <button className='notes'><FontAwesomeIcon icon={faNoteSticky} /></button>
                      <button className='notes text-warning'><FontAwesomeIcon icon={faCalendarWeek} /></button>
                      <span>Tue, May 28</span>
                </div>
            </div>
        </div>
        <div className='contents ps-5 mb-5'>
            <div className="one ps-5">
                <h4 className='heading'>93 days overdue</h4>
                <p>Webnexs Email Newsletter in webnexs HQ</p>
                <div className='task pt-0 d-flex'>
                      <input type='checkbox' /> 
                      <span className='title'>video-streaming-engine</span> 
                      <button className='notes'><FontAwesomeIcon icon={faNoteSticky} /></button>
                      <button className='notes text-warning'><FontAwesomeIcon icon={faCalendarWeek} /></button>
                      <span>Tue, May 28</span>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Overdue