import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Signup from './components/Authentication/Signup';
import Login from './components/Authentication/Login';
import Campfire from './components/ProjectPage/Campfire';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import ForgotPass from './components/Authentication/ForgotPass';
import { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import HomeNav from './components/HomePageComponents/HomeNav';
import ResetPassword from './components/Authentication/ResetPassword';
import ProjectCard from './components/ProjectPage/ProjectCard';
import Createproject from './components/HomePageComponents/Createproject';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import AddEmployees from './components/AddEmployees.js';
import Preferences from './components/Profile-Dropdown/Preferences.js';
import OutOfOffice from './components/Profile-Dropdown/OutOfOffice.js';
import MessageBoard from './components/ProjectPage/MessageBoard/MessageBoard.js';
import Adminland from './components/HomePageComponents/Adminland.js';

import PersonalCard from './components/Profile-Dropdown/PersonalCard.js'
import MyDevices from './components/Profile-Dropdown/MyDevices';
import NotificationSetting from './components/Profile-Dropdown/NotificationSetting.js';
import ProjectSchedule from './components/ProjectPage/ProjectSchedule.js';
import ToDos from './components/ProjectPage/To-Dos.js';
import DocsFiles from './components/ProjectPage/Docs&Files.js';
import Autocheckin from './components/ProjectPage/Autocheckin.js';
import EmailForward from './components/ProjectPage/EmailForward.js';
import CardTable from './components/ProjectPage/CardTable.js';
import NewMessage from './components/ProjectPage/MessageBoard/NewMessage.js';
import SetUpPeople from './components/ProjectPage/SetUpPeople.js';

import Assignment from './components/MyStuff/Assignment.js';
import Bookmark from './components/MyStuff/Bookmark.js';
import Schedule from './components/MyStuff/Schedule.js';
import Boosts from './components/MyStuff/Boosts.js';
import Drafts from './components/MyStuff/Drafts.js';

import Activity from './components/MyStuff/Recent_Activity/Activity.js';
import Latest_activity from './components/MyStuff/Recent_Activity/Latest_activity.js';
import Someones_Activity from './components/MyStuff/Recent_Activity/Someones_Activity.js';
import Someones_Assignment from './components/MyStuff/Recent_Activity/Someones_Assignment.js';
import Mission_control from './components/MyStuff/Recent_Activity/Mission_control.js';
import To_dos from './components/MyStuff/Recent_Activity/To_dos.js';
import UnassignedWork from './components/MyStuff/Recent_Activity/UnassignedWork.js';
import UpcomingDates from './components/MyStuff/Recent_Activity/UpcomingDates.js';
import TimeSheet from './components/MyStuff/Recent_Activity/TimeSheet.js';
import Overdue from './components/MyStuff/Recent_Activity/Overdue.js';

import View_All_List from './components/HomePageComponents/View_All_List.js';
import AutocheckinNewQuestion from './components/ProjectPage/AutocheckinNewQuestion.js';

import Invite_People from './components/HomePageComponents/Invite_People.js';
import Coworkers_Account from './components/HomePageComponents/Coworkers_Account.js';
import Invite_Link from './components/HomePageComponents/Invite_Link.js';
import Invitation_Identifier from './components/HomePageComponents/Invitation_Identifier.js';

import View_Template from './components/HomePageComponents/ViewTemp/View_Template.js';
import Project_Template from './components/HomePageComponents/ViewTemp/Project_Template.js';
import ToDo_List_Template from './components/HomePageComponents/ViewTemp/ToDo_List_Template.js';
import JustFollow from './components/ProjectPage/JustFollow.js';
import ProjectSettings from './components/ProjectPage/ProjectSettings.js';
import Administrators from './components/HomePageComponents/Adminland/Administrators.js';
import Changecontent from './components/HomePageComponents/Adminland/Changecontent.js';
import RenameProjectTools from './components/HomePageComponents/Adminland/RenameProjectTools.js';
import ChangeMessageCategories from './components/HomePageComponents/Adminland/ChangeMessageCategories.js';
import ChangePings from './components/HomePageComponents/Adminland/ChangePings.js';
import EditProjectSettings from './components/HomePageComponents/Adminland/EditProjectSettings.js';
import PlanSelection from './components/HomePageComponents/Adminland/PlanSelection.js';
import ManagePublicItems from './components/HomePageComponents/Adminland/ManagePublicItems.js';
import CancelYourAccount from './components/HomePageComponents/Adminland/CancelAccount.js';
import CancelAccount from './components/HomePageComponents/Adminland/CancelAccount.js';


import MergePeople from './components/HomePageComponents/Adminland/MergePeople.js';
import MoveProject from './components/HomePageComponents/Adminland/MoveProject.js';
import ManageGroups from './components/HomePageComponents/Adminland/ManageGroups.js';
import ManageCompanies from './components/HomePageComponents/Adminland/ManageCompanies.js';
import Archive_And_Delete_Project from './components/HomePageComponents/Adminland/Archive_And_Delete_Project.js';
import EditProject from './components/HomePageComponents/Adminland/EditProject.js';
import PublicLink from './components/HomePageComponents/Adminland/PublicLink.js';
import Two_Factor_Authentication from './components/HomePageComponents/Adminland/Two_Factor_Authentication.js';
import Ping_And_Chat_History from './components/HomePageComponents/Adminland/Ping_And_Chat_History.js';
import Add_Remove_Acc from './components/HomePageComponents/Adminland/Add_Remove_Acc.js';
import Account_Rename from './components/HomePageComponents/Adminland/Account_Rename.js';
import Trash from './components/HomePageComponents/Adminland/Trash.js';
import Reassignment from './components/HomePageComponents/Adminland/Reassignment.js';
import ProjectAccess from './components/HomePageComponents/Adminland/ProjectAccess.js';
import Export_Data from './components/HomePageComponents/Adminland/Export_Data.js';
import QuickAccess from './components/HomePageComponents/QuickAccess.js';
import MessageShow from './components/ProjectPage/MessageBoard/MessageShow.js';
import ManageStorage from './components/HomePageComponents/Adminland/ManageStorage.js';
import AddPeopleToProject from './components/ProjectPage/AddPeopleToProject.js';
import PrivateChatinPing from './components/HomePageComponents/Ping/PrivateChatinPing.js';
import TodoList from './components/ProjectPage/TodoList.js';


function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = cookies.token;
    if (token) {
      setIsSignedIn(true);
    } else {
      setIsSignedIn(false);
    }
  }, [cookies.token]);

  const signin = (token) => {
    setIsSignedIn(true);
    setCookie('token', token, { path: '/' }); // Set token in cookies
    localStorage.setItem('token', token); // Set token in localStorage
  };

  const signout = () => {
    setIsSignedIn(false);
    removeCookie('token', { path: '/' }); // Remove token from cookies
    localStorage.removeItem('token'); // Remove token from localStorage
    navigate('/login'); // Redirect to login page after logout
  };

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Retrieve user data from localStorage if available
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  return (
    <>
      {isSignedIn ? (
        <HomeNav onLogout={signout} />
      ) : (
        <Header />
      )}
      <Toaster />
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login onSignIn={signin} />} />

        <Route path="/" element={<Home userData={userData} />} />
        <Route path="/adminland" element={<Adminland />} />
        <Route path="/quickAccess" element={<QuickAccess />} />
        <Route path="/administrators" element={<Administrators />} />
        <Route path="/changecontent" element={<Changecontent />} />
        <Route path="/renameProjectTools" element={<RenameProjectTools />} />
        <Route path="/messageCategories" element={<ChangeMessageCategories />} />
        <Route path="/changePings" element={<ChangePings />} />
        <Route path="/editProjectSettings" element={<EditProjectSettings />} />
        <Route path="/planSelection" element={<PlanSelection />} />
        <Route path="/managePublicItems" element={<ManagePublicItems />} />
        <Route path="/cancelAccount" element={<CancelAccount />} />
        <Route path="view_All_List" element={<View_All_List />} />
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/project" element={<ProjectCard />} />

        <Route path="/setup-people" element={<SetUpPeople />} />
        <Route path="new-people" element={<AddPeopleToProject />} />
        <Route path="/createproject" element={<Createproject />} />
        <Route path="/chat" element={<Campfire />} />
        <Route path="/newMessage" element={<NewMessage />} />
        <Route path="/messageBoard" element={<MessageBoard />} />
        <Route path="/message/:id" element={<MessageShow />} />
        <Route path="/ProjectSchedule" element={<ProjectSchedule />} />
        <Route path="/toDos" element={<ToDos />} />
        <Route path="/todoList" element={<TodoList />} />
        <Route path="/autocheckin" element={<Autocheckin />} />
        <Route path="/Autocheckin-NewQuestion" element={<AutocheckinNewQuestion />} />
        <Route path="/docsFiles" element={<DocsFiles />} />
        <Route path="/invite" element={<AddEmployees />} />
        <Route path="/emailForward" element={<EmailForward />} />
        <Route path="/cardTable" element={<CardTable />} />
        <Route path="/justfollow" element={<JustFollow />} />
        <Route path="/projectSettings" element={<ProjectSettings />} />

        <Route path="/preferences" element={<Preferences />} />
        <Route path="/PersonalCard" element={<PersonalCard />} />
        <Route path="/Mydevices" element={<MyDevices />} />
        <Route path="/OutOfOffice" element={<OutOfOffice />} />
        <Route path="/NotificationSetting" element={<NotificationSetting />} />

        <Route path="/Assignment" element={<Assignment />} />
        <Route path="/Bookmark" element={<Bookmark />} />
        <Route path="/Schedule" element={<Schedule />} />
        <Route path="/Boosts" element={<Boosts />} />
        <Route path="/Drafts" element={<Drafts />} />
        <Route path="/Activitydd" element={<Activity />} />
        <Route path="/Latest_activity" element={<Latest_activity />} />

        <Route path="/Someones_Activity" element={<Someones_Activity />} />
        <Route path="/Someones_Assignment" element={<Someones_Assignment />} />
        <Route path="/Mission_control" element={<Mission_control />} />
        <Route path="/To_dos" element={<To_dos />} />
        <Route path="/UnassignedWork" element={<UnassignedWork />} />
        <Route path="/UpcomingDates" element={<UpcomingDates />} />
        <Route path="/TimeSheet" element={<TimeSheet />} />
        <Route path="/Overdue" element={<Overdue />} />
        <Route path="/Latest_activity" element={<Latest_activity />} />

        <Route path="/Invite_People" element={<Invite_People userData={userData} />} />
        <Route path="/Coworkers_Account" element={<Coworkers_Account userData={userData} />} />
        <Route path="/invite_Link" element={<Invite_Link />} />
        <Route path="/Invitation_Identifier" element={<Invitation_Identifier />} />

        <Route path="/View_Template" element={<View_Template />} />
        <Route path="/Project_Template" element={<Project_Template />} />
        <Route path="/ToDo_List_Template" element={<ToDo_List_Template />} />

        <Route path="/mergePeople" element={<MergePeople />} />
        <Route path="/moveProject" element={<MoveProject />} />
        <Route path="/manageGroups" element={<ManageGroups />} />
        <Route path="/manageCompanies" element={<ManageCompanies />} />
        <Route path="/archive_And_Delete_Project" element={<Archive_And_Delete_Project />} />
        <Route path="/editProject" element={<EditProject />} />
        <Route path="/publicLink" element={<PublicLink />} />
        <Route path="/two_Factor_Authentication" element={<Two_Factor_Authentication />} />
        <Route path="/ping_And_Chat_History" element={<Ping_And_Chat_History />} />
        <Route path="/add_Remove_Acc" element={<Add_Remove_Acc />} />
        <Route path="/account_Rename" element={<Account_Rename userData={userData} />} />
        <Route path="/trash" element={<Trash />} />
        <Route path="/reassignment" element={<Reassignment />} />
        <Route path="/projectAccess" element={<ProjectAccess />} />
        <Route path="/manageStorage" element={<ManageStorage />} />
        <Route path="/private-ping" element={<PrivateChatinPing />} />

        <Route path="/export_Data" element={<Export_Data />} />
      </Routes>
    </>
  );
}

export default App;