import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { GoFileSubmodule } from "react-icons/go";
import { Link } from 'react-router-dom';
const EmailForward = () => {

    return (
        <div className='campfire'>
            <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <Link to="/project" className="fw-bold">Webnexs HQ</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card text-center bg-dark campfireBody" style={{ width: '75vw' }}>
                    <div className=" text-center mt-3">
                        <a href="" className='headcamp fw-bold'> <h3 className="mb-0 text-light">There are two easy ways to forward an email to <br /> webnexs HQ</h3> </a>
                    </div>
                    <hr className="line" />
                    <div className='container p-5'>
                        <p className="fw-bold text-white m-0 d-flex align-items-center">
                            <span className="me-4 emailSno">1</span>Forward any email to
                            <a href='#'>save@3.TeamNex.com</a>
                        </p>
                        <p className='ms-5 mb-5 text-white text-start'>TeamNex will send you a follow up email asking where you’d like to save your forward.
                            Once you choose the TeamNex, we’ll save it and anyone on your team can add comments.</p>
                        <p className="fw-bold text-white d-flex m-0 align-items-center">
                            <span className="me-4 emailSno">2</span>Or use this special address to drop it directly into this Company HQ
                        </p>
                        <p className='ms-5 text-white text-start'>With this address, we won’t have to ask you where to put your forward
                            — it’ll go directly into webnexs HQ.</p>
                        <div className=" ms-5 text-start mb-1">
                            <input className='emailText' placeholder="save-9QmKciooUqg3@3.TeamNex.com" />
                            <button className='emailBtn mt-2 p-1'><GoFileSubmodule className='me-1' />Copy to clipboard</button>
                        </div>
                        <p className='ms-5 text-start emileNote'>Note: This address is personalized for you so don't share it with anyone else.
                            Everyone will see their own unique email address on this page.
                            (If someone else got a hold of it, you can <a href='#'>reset it</a>.)</p>
                        <div className='container mt-5 emailBox p-4'>
                            <h3 className='text-light text-start'>Common questions about forwarding an email:</h3>
                            <p className="fw-bold text-white d-flex m-0 align-items-center">
                                Who is notified when I forward an email to TeamNex?
                            </p>
                            <p className='text-white text-start'>No one is notified automatically.
                                If you’d like to include others, you can change who gets notified at the bottom of the comments section.</p>
                            <p className="fw-bold text-white d-flex m-0 align-items-center">
                                Does TeamNex save attachments?
                            </p>
                            <p className='text-white text-start'>Any file attached to the forwarded email will be saved with the email in TeamNex.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailForward
