import React from 'react';
import { LuKey } from "react-icons/lu";
import { Link } from 'react-router-dom';
const Trash = () => {
  return (
    <div className='Trash'>
    <div className=' text-center d-block m-auto card--head' style={{ width: '65vw', height: '6vh' }}>
        <div className='d-flex justify-content-center align-items-center mt-2'>
            <Link to='/View_Template' className='m-2 fw-bold'><LuKey /> Adminland</Link>
        </div>
    </div>
    <div className="d-flex justify-content-center align-items-center">
        <div className="body-bottom text-center" style={{ width: '75vw' }}>
                <div className="container p-3">
                    <div className="">
                        <p className='h2 fw-bold pt-3'>Everything in the trash</p>
                    </div>
                    <hr />
                    <div className='body-content px-5 mx-auto'>
                        <div className=' text-center'>
                        <p className='text-center'>The items below will be permanently erased 25 days after they were trashed. The data may remain on Basecamp’s internal systems and backups for up to 30 days before it’s completely destroyed.</p>
                        

                        <div class="content-between-lines">
                           <hr class="linee" />
                               <span class="textt">The trash is empty.</span>
                            <hr class="linee" />
                        </div>
                        </div>
                     
                    </div>
                </div>
       </div>
    </div>
</div>
  )
}

export default Trash