import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import toast from "react-hot-toast";

const CreateProject = () => {
  const [showInputs, setShowInputs] = useState(false);
  const [switchStates, setSwitchStates] = useState({
    messageBoard: true,
    todos: true,
    docsFiles: true,
    chat: true,
    schedule: true,
    cardTable: true,
    trackTime: false,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [inviteNote, setInviteNote] = useState("");
  const [invites, setInvites] = useState("");

  const navigate = useNavigate();
  console.log('process.env.REACT_APP_API_URL = ', process.env.REACT_APP_API_URL)
  const handleSwitchChange = (name) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleClick = () => {
    setShowInputs(!showInputs);
  };

  const handleOptionsClick = () => {
    setShowOptions(!showOptions);
  };

  const handleShowNoteClick = () => {
    setShowNotes(!showNotes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Gather and structure form data
    const projectData = {
      name,
      description,
      startDate: startDate ? startDate.toISOString().split('T')[0] : null,
      endDate: endDate ? endDate.toISOString().split('T')[0] : null,
      organization: "", // Make sure to replace this with actual organization ID
      tools: switchStates,
      inviteOnly: true,
      members: invites.split(',').map(member => member.trim()).filter(member => member)
    };

    try {
      // const response = await axios.post(`https://basecamp-admin.webnexs.org/project`, projectData);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/project`, projectData);
      console.log('Project created successfully:', response.data);
      toast.success('Project created successfully!');
      navigate('/home'); // Redirect to a success page or another route
    } catch (error) {
      console.log(projectData);
      console.error('Error creating project:', error);
      // You might want to show an error message to the user
    }
  };

  return (
    <div className="container create-project-container">
      <h2 className="fs-1 fw-bold text-white text-center mb-4">Make a new project</h2>
      <div className="create-project">
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div className="card-container pb-5 project-contain px-5">
              <div className="px-3 pt-5">
                <div>
                  <h2 className="fs-4 fw-bold text-white d-flex align-items-center">
                    <span className="me-3 sno">1</span>Start with the basics
                  </h2>
                  <div className="d-flex justify-content-between pro-label mt-3">
                    <h4 className="text-white fs-6 fw-bold ps-4">Name of the Project</h4>
                    <Link to="/view-template" className="fs-6">Create a project template</Link>
                  </div>
                  <input
                    type="text"
                    className="proinput ms-4 px-2"
                    placeholder="e.g. Office Renovation"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <h4 className="text-white fs-6 fw-bold ps-4 pt-4">Add a description (Optional)</h4>
                  <input
                    type="text"
                    className="proinput ms-4 px-2 pb-5"
                    placeholder="e.g. Plans and scheduling for expanding the office"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />

                  <h4 className="text-white fs-6 fw-bold ps-4 pt-4">Set the schedule (optional)</h4>
                  <Link to="#" className="ps-5 text-secondary" onClick={handleClick}>
                    Add start/end dates
                  </Link>
                  {showInputs && (
                    <div>
                      <div className="mt-3 d-flex ps-5">
                        <div className="form-group pe-3">
                          <label htmlFor="startDate" className="text-white fs-6 fw-bold">Start Date</label>
                          <br />
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            placeholderText="Choose start date"
                            className="dateinput px-2"
                          />
                        </div>
                        <div className="form-group ps-5">
                          <label htmlFor="endDate" className="text-white fs-6 fw-bold">End Date</label>
                          <br />
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            placeholderText="Choose end date"
                            className="dateinput px-2"
                          />
                        </div>
                      </div>
                      <Link to="#" className="ps-5 text-secondary pt-2" onClick={handleClick}>
                        Remove date
                      </Link>
                    </div>
                  )}
                  <h4 className="text-white fs-6 fw-bold ps-4 pt-4">Track time on this project (optional)</h4>
                  <div className="pt-2 ps-5">
                    {/* <BootstrapSwitchButton
                      checked={switchStates.trackTime}
                      onlabel="On"
                      offlabel="Off"
                      onstyle="switch-on"
                      offstyle="switch-off"
                      onChange={() => handleSwitchChange("trackTime")}
                      className="custom-switch"
                    /> */}


                    {/* <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"></input>
                    </div> */}
                    <div class="col-sm-5">
                      <button type="button" class="btn btn-sm btn-toggle" data-toggle="button" aria-pressed="false" autocomplete="off">
                        <div class="handle"></div>
                      </button>
                    </div>

                  </div>

                  <h2 className="fs-4 fw-bold text-white d-flex align-items-center pt-3">
                    <span className="me-3 sno">2</span>Choose your tools
                  </h2>
                  <span className="text-secondary fs-6 ps-4">You can always change tools later</span>
                  <div className="row d-flex justify-content-center">
                    {[
                      { name: "Message Board", description: "Post announcements, pitch ideas, progress updates, etc. and keep feedback on-topic", key: "messageBoard" },
                      { name: "To-dos", description: "Make lists of work that needs to get done, assign items, set due dates, and discuss.", key: "todos" },
                      { name: "Docs & Files", description: "Share docs, files, images, and spreadsheets. Organize in folders so they’re easy to find.", key: "docsFiles" },
                      { name: "Chat", description: "Chat casually with the group, ask random questions, and share stuff without ceremony.", key: "chat" },
                      { name: "Schedule", description: "Set important dates on a shared schedule. Subscribe to events in Google Cal, iCal, or Outlook.", key: "schedule" },
                      { name: "Card Table", description: "A visual, Kanban-like tool for process-oriented work. Establish a workflow and move cards across columns.", key: "cardTable" },
                    ].map((tool) => (
                      <div className="col-12 col-lg-4 col-md-6 col-sm-6 m-0 border toolsbox mt-2" key={tool.key}>
                        <h1 className="pt-4 text-center fs-6 fw-bold text-white">{tool.name}</h1>
                        <div className="pt-2 ps-5">
                          <BootstrapSwitchButton
                            checked={switchStates[tool.key]}
                            onlabel="On"
                            offlabel="Off"
                            onstyle="switch-on"
                            offstyle="switch-off"
                            onChange={() => handleSwitchChange(tool.key)}
                            className="custom-switch"
                          />
                        </div>
                        <p className="text-white text-align-justify">{tool.description}</p>
                      </div>
                    ))}
                  </div>
                  <h2 className="fs-4 fw-bold text-white d-flex align-items-center pt-3">
                    <span className="me-3 sno">3</span>Choose who’s on the project
                  </h2>
                  <p className="text-secondary ps-4">
                    This project is <b>Invite-only</b>. Only people who are explicitly invited can see this project{" "}
                    <Link className="text-secondary" onClick={handleOptionsClick}>(change)</Link>
                  </p>
                  {showOptions && (
                    <div className="ps-4">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="projectVisibility" id="inviteOnly" defaultChecked />
                        <label className="form-check-label text-secondary" htmlFor="inviteOnly">
                          Invite-only <span>Only people invited to this project can view</span>
                        </label>
                      </div>
                      <div className="form-check pt-3">
                        <input className="form-check-input" type="radio" name="projectVisibility" id="teamVisible" />
                        <label className="form-check-label text-secondary" htmlFor="teamVisible">
                          Visible to anyone on the team <span>Everyone on your team can view this project</span>
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="mt-4 d-flex justify-content-center">
                    <textarea
                      className="proinput ms-3 text-white"
                      placeholder="e.g. John, Sarah, Kevin"
                      value={invites}
                      onChange={(e) => setInvites(e.target.value)}
                    ></textarea>
                  </div>
                  <Link to="#" className="text-secondary ps-5 pt-3" onClick={handleShowNoteClick}>
                    Add invite note (optional)
                  </Link>
                  {showNotes && (
                    <div className="mt-4 d-flex justify-content-center">
                      <textarea
                        className="proinput ms-3 text-white"
                        placeholder="Include a personal note to your invite"
                        value={inviteNote}
                        onChange={(e) => setInviteNote(e.target.value)}
                      ></textarea>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button type="submit" className="Projectbtn">
                Create project
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateProject;