// import React, { useState } from 'react';

// function TodoList() {
//   const [listName, setListName] = useState('');
//   const [details, setDetails] = useState('');
//   const [isTracked, setIsTracked] = useState(false);

//   const handleAddList = async () => {
//     const newList = {
//       title: listName,
//       description: details,
//       createdBy: 'user_id_here',  // Replace with actual user ID
//     };

//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/api/todolists`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newList),
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('List added successfully:', data);
//         // Reset the form after successful submission
//         setListName('');
//         setDetails('');
//         setIsTracked(false);
//       } else {
//         const errorData = await response.json();
//         console.error('Error:', errorData);
//         alert('Failed to add list: ' + errorData.error);
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       alert('An error occurred while adding the list');
//     }
//   };

//   return (
//     <div style={styles.container}>
//       <input
//         type="text"
//         placeholder="Name this list..."
//         value={listName}
//         onChange={(e) => setListName(e.target.value)}
//         style={styles.input}
//       />
//       <textarea
//         placeholder="Add extra details or attach a file..."
//         value={details}
//         onChange={(e) => setDetails(e.target.value)}
//         style={styles.textarea}
//       />
//       <div style={styles.actions}>
//         <button onClick={handleAddList} style={styles.addButton}>
//           Add this list
//         </button>
//         <button style={styles.cancelButton}>Cancel</button>
//         <label style={styles.checkboxLabel}>
//           <input
//             type="checkbox"
//             checked={isTracked}
//             onChange={(e) => setIsTracked(e.target.checked)}
//           />
//           Track this list on the Hill Chart
//         </label>
//       </div>
//     </div>
//   );
// }

// const styles = {
//   container: {
//     padding: '20px',
//     backgroundColor: '#1d2d38',
//     borderRadius: '8px',
//     width: '300px',
//     color: '#fff',
//   },
//   input: {
//     width: '100%',
//     padding: '8px',
//     marginBottom: '10px',
//     borderRadius: '4px',
//     border: '1px solid #ccc',
//     backgroundColor: '#2e3b48',
//     color: '#fff',
//   },
//   textarea: {
//     width: '100%',
//     padding: '8px',
//     marginBottom: '10px',
//     borderRadius: '4px',
//     border: '1px solid #ccc',
//     backgroundColor: '#2e3b48',
//     color: '#fff',
//   },
//   actions: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
//   addButton: {
//     padding: '8px 16px',
//     backgroundColor: '#00b3e6',
//     border: 'none',
//     borderRadius: '4px',
//     color: '#fff',
//     cursor: 'pointer',
//   },
//   cancelButton: {
//     padding: '8px 16px',
//     backgroundColor: '#555',
//     border: 'none',
//     borderRadius: '4px',
//     color: '#fff',
//     cursor: 'pointer',
//   },
//   checkboxLabel: {
//     display: 'flex',
//     alignItems: 'center',
//     color: '#ccc',
//   },
// };

// export default TodoList;
