import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCheck } from '@fortawesome/free-solid-svg-icons';


const Latest_activity = () => {
  return (
    <div className='Latest-Act container w-75 p-3'>
      <div className="row align-items-center p-2">
        <div className="col">

        </div>
        <div className="col text-center mt-3 h2">
          <p>Latest Activity</p>
        </div>
        <div className="col text-end">
          <button className="emailbtn p-2">
            <FontAwesomeIcon className='' icon={faCheck} /> Emailing a Daily Summary
          </button>
        </div>
      </div>
      <hr />
      <div className='content-act-body d-flex justify-content-center'>
        <div className='btn3'>
          <button className='btn-3'>Everything</button>
          <button className='btn-3'>Filter by project</button>
          <button className='btn-3'>Filter by people</button>
        </div>
      </div>
      <div className='d-flex justify-content-center m-2'>
        <input className='form-control w-75 project-ip' type="text" name="" id="" placeholder='Find projects...' />
        <button className='filter-btn'>Filter</button>
      </div>
      <div className="tab-content d-block mx-auto w-50 p-2">
        <div className='content d-flex justify-content-center align-item-center'>
          <FontAwesomeIcon className='me-3 mt-2 Bmicon' icon={faBell} />
          <p> <strong>No activity just yet </strong><br />
            Get an overview of what's happening across<br />
            Basecamp right here.
          </p>
        </div>

      </div>
    </div>
  )
}

export default Latest_activity