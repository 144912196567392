import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaPlus } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { FaRegBookmark } from 'react-icons/fa6';
import { MdEdit, MdOutlineSort } from "react-icons/md";
import { GoDash } from "react-icons/go";

const MessageBoard = () => {
    const [selectedDay, setSelectedDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const navigate = useNavigate();

    const days = [
        { value: '', label: 'All Messages' },
        { value: 'Announcement', label: 'Announcement' },
        { value: 'FYI', label: 'FYI' },
        { value: 'Pitch', label: 'Pitch' },
        { value: 'Heartbeat', label: 'Heartbeat' },
        { value: 'Question', label: 'Question' },
        { value: 'DM', label: 'DM' },
        { value: 'Personal mastery', label: 'Personal mastery' },
    ];

    const handleChange = (event) => {
        setSelectedDay(event.target.value);
    };

    const openNav = () => {
        setIsOpen(true);
    };

    const closeNav = () => {
        setIsOpen(false);
    };

    const handleClickMessage = (messageId) => {
        navigate(`/message/${messageId}`);
    };

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await fetch(`https://basecamp-admin.webnexs.org/api/messages`);
                if (!response.ok) {
                    throw new Error('Failed to fetch messages');
                }
                const data = await response.json();
                setMessages(data);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();
    }, []);

    return (
        <div className='campfire'>
            <div className='text-center d-block m-auto bg-dark campfire card--head camp' style={{ width: '65vw' }}>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <Link to="/project" className="fw-bold">Webnexs HQ</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card text-center" style={{ width: '75vw' }}>
                    <div className="container campfireBody">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="text-start">
                                <Link to="/newMessage">
                                    <button className="newEvent px-2 py-1 text-dark">
                                        <FaPlus className='me-1' />New message
                                    </button>
                                </Link>
                            </div>
                            <div className="text-center">
                                <a href="#" className='headcamp fw-bold'>
                                    <h3 className="mb-0 text-light">
                                        {selectedDay === '' ? 'All Messages' : `${days.find(day => day.value === selectedDay).label}`}
                                    </h3>
                                </a>
                            </div>
                            <div className="text-end">
                                <select
                                    className='allMessages text-light'
                                    id="day-selector"
                                    value={selectedDay}
                                    onChange={handleChange}
                                >
                                    {days.map((day) => (
                                        <option key={day.value} value={day.value}>
                                            {day.label}
                                        </option>
                                    ))}
                                </select>
                                <button className="Notifybtn text-light" onClick={openNav}>
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                </button>
                            </div>
                        </div>
                        <hr className="line" />
                    </div>
                    <div className="card-body campfireBody" >
                        <div id="messages" className='text-start ms-5 me-5 p-4' style={{ cursor: 'pointer' }}>
                            {messages.map((message) => (
                                <div
                                    key={message._id}
                                    className='messageBoard text-decoration-none'
                                    onClick={() => handleClickMessage(message._id)}
                                >
                                        <img src={message.profileImg} alt="profile" className='msgProfile text-start' />
                                    <div className='d-flex flex-column ms-5'>
                                        <h4 className='text-light ms-2'>{message.title || 'Unknown Author'}</h4>
                                        <p className='text-light ms-2'><span className='pfusername'>{message.username}Arun kumar . Aug 23<GoDash /></span>{message.content || 'No content available.'}</p>
                                        {message.link && <a className='text-light' href={message.link}>{message.link}</a>}
                                    </div>
                                    <hr className="line m-0" />
                                </div>
                            ))}
                        </div>
                        <div className="text-start">
                            <div id="mySidenav" className={`CampfireSidenav messageboardSidenav ${isOpen ? 'open' : ''}`}>
                                <span className="closebtn" onClick={closeNav}>&times;</span>
                                <div className="P-2">
                                    <Link to="#" className='sideBar text-decoration-none'>
                                        <button className='me-2 sidebarBtn'><FaRegBookmark /></button>Bookmark
                                    </Link>
                                </div>
                                <div className="P-2">
                                    <Link to="#" className='sideBar text-decoration-none'>
                                        <button className='me-2 sidebarBtn'><MdOutlineSort /></button>Sort messages by...
                                    </Link>
                                </div>
                                <div className="P-2">
                                    <Link to="#" className='sideBar text-decoration-none'>
                                        <button className='me-2 sidebarBtn'><MdEdit /></button>Edit categories...
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageBoard;
