import ProfileCircles from './ProfileCircles';


const AutocheckinShow = ({ data }) => {
    const acc = [
        {
            username: "Arun Pandiyan",
            profilePic: null
        },
        {
            username: "Pragatheeshwaran",
            profilePic: null
        },
        {
            username: "Arun Kmumar",
            profilePic: null
        }
    ];

    return (
        <div className="cardbox">
            <h2 className="card-title text-white fw-bold fs-5 text-center border-bottom py-2">Automatic Check-ins</h2>
            <div className="card-body px-3 pt-2 overflow-hidden">
                <div className="card-item pb-1">
                    <div className=''>
                        <p className='text-light item-paragraph text-start m-0'>Asking 31 people every weekday at 6:30pm.</p>
                        <p className='text-light item-title m-0 text-start'>What was that on your plate today?</p>
                        <ProfileCircles className='mb-1' accounts={acc} />
                    </div>
                    <div className=''>
                        <p className='text-light item-paragraph text-start m-0'>Asking 31 people every weekday at 6:30pm.</p>
                        <p className='text-light item-title m-0 text-start'>What was that on your plate today?</p>
                        <ProfileCircles accounts={acc} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AutocheckinShow;