import React from 'react'

const Mission_control = () => {
  return (
    <div className='Mission_con container p-3'>
        <hr />
        <div className="d-flex justify-content-center align-items-center pt-2" >
            <div className="text-center">
                   <p className="fw-bold h3">Mission Control</p>
                   <p className="h6">See how the needle is moving on your projects</p>
            </div>
        </div>
        <div className="tab-content d-block mx-auto w-50 pt-3 mt-5">
        
          <div className='content d-flex justify-content-center align-item-center'>
            
            <p> <b>No projects with needle yet</b> <br />
                 Bring visibility to project progress by enabling the needle <br />
                 under project settings.</p>
          </div>
       
      </div>

       
    </div>
  )
}

export default Mission_control