import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const JustFollow = () => {
    return (
        <div className='campfire'>
            <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <Link to="/project" className="fw-bold">Webnexs HQ</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card text-center" style={{ width: '75vw' }}>
                    <div className="card bg-dark campfireBody">
                        <div className="container ">
                            <div className="d-flex justify-content-center">
                                <div className=" text-center mt-3">
                                    <a href="" className='headcamp fw-bold'> <h3 className="mb-0 text-light">Just want to follow this Company HQ?</h3> </a>
                                </div>
                            </div>
                            <hr className="line" />
                            <div className='ps-5 pe-5'>
                            <p className='text-light text-center'>You’ll still have full access to the Company HQ, but you won’t be auto-subscribed to new threads.
                                You won’t hear from the Company HQ unless someone specifically @mentions you
                                , subscribes you to a thread or assigns you a to-do.</p>
                                </div>
                                <div className="buttons d-flex justify-content-center">
                                        <button type="button" className="save-draft me-3">Yes, switch to Just following</button>
                                        <button type="submit" className="post-message">Never mind</button>
                                    </div>
                        </div>
                        <div className="Calendar-cont d-block mx-auto p-3">
                        </div>
                        <div className="card-body bg-dark" style={{ height: '70vh' }} >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JustFollow;
