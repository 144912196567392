import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GiPin } from "react-icons/gi";
import axios from 'axios';

const View_All_List = () => {
    const [projects, setProjects] = useState([]);
    const [filter, setFilter] = useState('all');
    const [loading, setLoading] = useState(true); // Loading state

    // Fetch projects from the API when the component mounts
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get('http://localhost:3334/api/projectlist/listprojects');
                console.log(response.data.projectList); // Inspect the structure here
                setProjects(response.data.projectList || response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching projects:", error);
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);


    const togglePin = (id) => {
        setProjects(projects.map(project =>
            project._id === id ? { ...project, pinned: !project.pinned } : project
        ));
    };

    const headerTitle = {
        all: 'All Projects',
        pinned: 'Pinned Projects',
        projects: 'Just Projects',
        teams: 'Just Teams',
        clients: 'With Clients',
        'all-access': 'All-access Projects',
        archived: 'Archived & Trashed Projects',
    }[filter] || 'All Projects';

    const filteredProjects = projects.filter(project => {
        switch (filter) {
            case 'pinned':
                return project.pinned;
            case 'projects':
                return project.type === 'personal'; // Adjust according to actual type values
            case 'teams':
                return project.type === 'team'; // Adjust according to actual type values
            case 'clients':
                return project.type === 'client'; // Adjust according to actual type values
            case 'all-access':
                return project.type === 'all-access';
            case 'archived':
                return project.archived; // Assuming you have an 'archived' attribute
            default:
                return true; // Shows all projects
        }
    });

    // Handle loading state
    if (loading) {
        return <div>Loading projects...</div>;
    }

    return (
        <div className="projects-container mt-4">
            <div className="projects-header">
                <h3 className='mt-3'>{headerTitle}</h3>
            </div>
            <span className='projects-header mt-2'>
                <input type="text" className='viewallText' placeholder="Find a project..." />
            </span>
            <div className="projects-filters">
                <button onClick={() => setFilter('all')}>A-Z</button>
                <button onClick={() => setFilter('pinned')}>Pinned</button>
                <button onClick={() => setFilter('projects')}>Just Projects</button>
                <button onClick={() => setFilter('teams')}>Just Teams</button>
                <button onClick={() => setFilter('clients')}>With Clients</button>
                <button onClick={() => setFilter('all-access')}>All-access</button>
                <button onClick={() => setFilter('archived')}>Archived & Trashed</button>
            </div>
            <div className='d-flex justify-content-center'>
                <div className="project-list">
                    {filteredProjects.map(project => (
                        <div key={project._id} className={`project-item ${project.type || ''}`}>
                            <span>{project.type ? project.type.charAt(0).toUpperCase() : ''}</span> {/* Fallback to '?' if type is undefined */}
                            <Link to="#" className='text-decoration-none text-light'>
                                <h6 className='m-0'>
                                    {project.name} {project.type === 'hq' && <span className="hq-tag">HQ</span>}
                                </h6>
                                {project.description && <p className='user-role'>{project.description}</p>}
                            </Link>
                            <button
                                className="pin-button"
                                onClick={() => togglePin(project._id)}
                            >
                                {project.pinned ?
                                    <div className="color-icon"><GiPin /></div>
                                    :
                                    <div className="pin-icon"><GiPin /></div>
                                }
                            </button>
                            {filter === 'all-access' && <button className="listBtn ms-2">Join</button>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default View_All_List;