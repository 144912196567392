import React, { useState } from "react";
import PasswordForm from "./PasswordFrom";

const Signup = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    companyname: "",
    password: "",
  });
  const [error, setError] = useState(null); // State to hold error message
  const [isLoading, setLoading] = useState(false); // State to manage loading state
  const [isSign, setSignUp] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setSignUp(true); // Move to password form
    } catch (err) {
      setError(err.message);
      console.log(err);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return isSign ? (
    <PasswordForm formData={formData} setFormData={setFormData} />
  ) : (
    <div className="d-flex justify-content-center align-items-center signup">
      <div
        className="Loginpage text-center"
        style={{ maxWidth: "500px", width: "100%" }}
      >
        <img
          src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public'
          alt="Logo"
          className="d-block m-auto"
          style={{ width: "100px" }}
        />
        <h5 className="card-title text-center mb-3 text-white">Try TeamNex for free</h5>
        <h3 className="text-center card-title text-m text-white">
          No credit card required • Cancel anytime
        </h3>
        <div className="card p-4 mt-4 shadow-sm">
          <div className="card-body">
            <h3 className="text-center card-title text-mm mb-4 text-white">
              Just need a few things to get you going
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Your name"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control mb-2"
                  placeholder="Your email (e.g. julie.young@hey.com)"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Your organization's name"
                  id="companyname"
                  name="companyname"
                  value={formData.companyname}
                  onChange={handleChange}
                  required
                />
              </div>
              <button
                type="submit"
                className="btn btn-success btn-block mt-3 w-100"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Last step: you’ll set a password"}
              </button>
              {error && <p className="text-danger mt-2">{error}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
