import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaBold, FaHeading, FaItalic, FaLink, FaStrikethrough } from 'react-icons/fa';
import { IoIosAttach, IoIosColorFill, IoMdCode } from 'react-icons/io';
import { BiSolidQuoteAltRight } from 'react-icons/bi';
import { LuRedo, LuUndo } from 'react-icons/lu';
import { MdFormatListBulleted, MdFormatListNumbered } from 'react-icons/md';
import { AiOutlineAlignCenter } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import toast from 'react-hot-toast';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const NewMessage = () => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [messages, setMessages] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const textareaRef = useRef(null);
  const navigate = useNavigate();
  const socketRef = useRef(null); // Reference to Socket.IO connection
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const [isEditorActive, setIsEditorActive] = useState(false);



  const categories = [
    { value: 'None', label: 'Pick a category (optional)' },
    { value: 'Announcement', label: 'Announcement' },
    { value: 'FYI', label: 'FYI' },
    { value: 'Pitch', label: 'Pitch' },
    { value: 'Heartbeat', label: 'Heartbeat' },
    { value: 'Question', label: 'Question' },
    { value: 'DM', label: 'DM' },
    { value: 'Personal mastery', label: 'Personal mastery' },
  ];

  // Initialize socket connection
  useEffect(() => {
    socketRef.current = io(`${process.env.REACT_APP_API_URL}`);

    // Cleanup on component unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const usernameId = '66bee1a7f5207463205143d6'; // Assuming you have the current user stored in a state

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/messages`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: usernameId,
          title,
          content,
          category: selectedCategory,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to post message: ${errorText}`);
      }

      const newMessage = await response.json();
      toast.success('Message Created successfully!');
      setTitle('');
      setContent('');
      setSelectedCategory('');

      // Emit a socket event to notify other users of the new message
      socketRef.current.emit('newMessage', newMessage);

      navigate(`/message/${newMessage._id}`);
    } catch (error) {
      console.error('Error posting message:', error.message);
      toast.error(`Error posting message: ${error.message}`);
    }
  };


  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const wrapSelectedText = (tagOpen, tagClose = tagOpen) => {
    const textarea = textareaRef.current;
    if (!textarea) {
      console.error('Textarea reference is not defined');
      return;
    }
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const content = textarea.value;

    if (start !== end) {
      const selectedText = content.substring(start, end);
      const newText = `${content.substring(0, start)}${tagOpen}${selectedText}${tagClose}${content.substring(end)}`;
      setContent(newText);
    }
  };

  const saveState = () => {
    setHistory((prevHistory) => [...prevHistory, content]);
    setRedoStack([]); // Clear redoStack when a new action is performed
  };

  const handleUndo = () => {
    setHistory((prevHistory) => {
      if (prevHistory.length > 0) {
        const previousContent = prevHistory[prevHistory.length - 1];
        setRedoStack((prevRedoStack) => [content, ...prevRedoStack]);
        setContent(previousContent);
        return prevHistory.slice(0, -1);
      }
      return prevHistory;
    });
  };

  const handleRedo = () => {
    setRedoStack((prevRedoStack) => {
      if (prevRedoStack.length > 0) {
        const nextContent = prevRedoStack[0];
        saveState();
        setContent(nextContent);
        return prevRedoStack.slice(1);
      }
      return prevRedoStack;
    });
  };

  // Text formatting handlers
  const handleBold = () => wrapSelectedText('<b>', '</b>');
  const handleItalic = () => wrapSelectedText('<i>', '</i>');
  const handleStrikethrough = () => wrapSelectedText('<s>', '</s>');
  const handleLink = () => {
    const url = prompt('Enter the URL:');
    if (url) {
      wrapSelectedText(`<a href="${url}">`, '</a>');
    }
  };

  const handleColorFill = () => {
    const color = prompt('Enter the color (e.g., #ff0000):');
    if (color) {
      wrapSelectedText(`<span style="color: ${color};">`, '</span>');
    }
  };

  const handleHeading = () => {
    const level = parseInt(prompt('Enter heading level (1-6):'), 10);
    if (level >= 1 && level <= 6) {
      wrapSelectedText(`<h${level}>`, `</h${level}>`);
    } else {
      alert('Invalid heading level.');
    }
  };

  const handleBlockquote = () => wrapSelectedText('<blockquote>', '</blockquote>');
  const handleAlignCenter = () => wrapSelectedText('<div style="text-align: center;">', '</div>');
  const handleCode = () => wrapSelectedText('<code>', '</code>');
  const handleBulletedList = () => wrapSelectedText('<ul><li>', '</li></ul>');
  const handleNumberedList = () => wrapSelectedText('<ol><li>', '</li></ol>');
  const handleAttach = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.addEventListener('change', (event) => {
      const file = event.target.files[0];
      if (file) {
        console.log('File selected:', file);
      }
    });
    input.click();
  };

  return (
    <div className="campfire">
      <div className="text-center d-block m-auto campfire card--head camp" style={{ width: '65vw', height: '6vh' }}>
        <div className="d-flex justify-content-center align-items-center mt-2">
          <button className="icon--button me-2">
            <FontAwesomeIcon className="me-1" icon={faTh} />
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <Link to="/messageBoard" className="fw-bold">Webnexs HQ</Link>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center camp">
        <div className="card text-center" style={{ width: '75vw' }}>
          <div className="card bg-dark campfireBody">
            <div className="container p-5">
              <div className="text-start mb-3">
                <select className="allcategory text-light" id="category-selector" value={selectedCategory} onChange={handleCategoryChange}>
                  {categories.map((category) => (
                    <option key={category.value} value={category.value}>{category.label}</option>
                  ))}
                </select>
              </div>
              <input
                className="categoryText pt-1"
                placeholder="Type a title..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
             
             <div className="toolbar text-start">
                <hr className="line m-0" />
                <button className="toolbarBtn" onClick={handleBold}><b><FaBold /></b></button>
                <button className="toolbarBtn" onClick={handleItalic}><i><FaItalic /></i></button>
                <button className="toolbarBtn" onClick={handleStrikethrough}><FaStrikethrough /></button>
                <button className="toolbarBtn" onClick={handleLink}><FaLink /></button>
                <button className="toolbarBtn" onClick={handleColorFill}><IoIosColorFill /></button>
                <button className="toolbarBtn" onClick={handleHeading}><FaHeading /></button>
                <button className="toolbarBtn" onClick={handleBlockquote}><BiSolidQuoteAltRight /></button>
                <button className="toolbarBtn" onClick={handleAlignCenter}><AiOutlineAlignCenter /></button>
                <button className="toolbarBtn" onClick={handleCode}><IoMdCode /></button>
                <button className="toolbarBtn" onClick={handleBulletedList}><MdFormatListBulleted /></button>
                <button className="toolbarBtn" onClick={handleNumberedList}><MdFormatListNumbered /></button>
                <button className="toolbarBtn" onClick={handleAttach}><IoIosAttach /></button>
                <span className="text-end">
                  <button className="toolbarBtn" onClick={handleUndo}><LuUndo /></button>
                  <button className="toolbarBtn" onClick={handleRedo}><LuRedo /></button>
                </span>
                <hr className="line m-0" />
              </div>
              <textarea
                className="awayText pt-1"
                placeholder="Write away..."
                value={content}
                onChange={(e) => setContent(e.target.value)}
                ref={textareaRef}
              />
            </div>
            <div className="notification-settings p-5">
              <h5 className="text-start">When I post this, notify...</h5>
              <form onSubmit={handleSubmit}>
                <div className="radio-group">
                  <label><input type="radio" name="notify" defaultChecked /> Just the people working on this Company HQ</label>
                  <div className="user-avatars">{/* Add user avatars here */}</div>
                </div>
                <div className="radio-group">
                  <label><input type="radio" name="notify" /> All people who can see this Company HQ</label>
                </div>
                <div className="radio-group">
                  <label><input type="radio" name="notify" /> Only the people I select...</label>
                </div>
                <div className="radio-group">
                  <label><input type="radio" name="notify" /> No one</label>
                </div>
                <div className="buttons">
                  <button type="button" className="save-draft me-3">Save as a draft</button>
                  <button type="submit" className="post-message">Post this message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMessage;
