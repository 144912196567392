import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';

const PasswordForm = ({ formData, setFormData }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://basecamp-admin.webnexs.org/register`, formData);
      // const response = await axios.post(`http://localhost:3334/register`, formData);
      localStorage.setItem('authToken', true);
      navigate('/login');
      toast.success('Signup successful! You can now log in.');
      console.log('Signup successful:', response.data);
    } catch (error) {
      console.error('Signup error:', error);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center mt-5 password">
      <div className="Loginpage text-center" style={{ maxWidth: '448px', width: '100%' }}>
        <img src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public' alt="Logo" className="d-block m-auto" style={{ width: '100px' }} />
        <h5 className="card-title text-center text-light mb-3">Try TeamNex for free</h5>
        <h3 className="text-center card-title text-light text-m">No credit card required • Cancel anytime</h3>
        <div className="card p-4 mt-4 shadow-sm">
          <div className="card-body">
            <h2 className="h6 mt-0 mb-2 text-light text-center fw-bold">Now pick a password and you’re all set</h2>
            <p className="mt-0 mb-2 text-light">For your security, we require that passwords be at least 12 characters.</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group position-relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control mb-2"
                  name="password"
                  placeholder="Type your password here…"
                  onChange={handleChange}
                  value={formData.password || ''}
                  required
                />
                <span
                  type="button"
                  className=" position-absolute align-items-center end-0 top-0 mt-2 me-2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </span>
              </div>
              <button type="submit" className="btn btn-success btn-block mt-3 w-100">
                Create my new account
              </button>
            </form>
          </div>
        </div>
        <p className="text-light text-center mt-3">
          Questions? Need a hand? <a href="#">Support can help.</a>
        </p>
      </div>
    </div>
  );
};

export default PasswordForm;
