import React,{useState} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';

const Assignment = () => {
    const [activeTab, setActiveTab] = useState('assignments');
  return (

    <div className='Assignment container w-75 p-3'>
                <div className="row align-items-center p-2">
                  <div className="col">
                    
                  </div>
                  <div className="col text-center mt-3">
                  <button className="rounded-circle d-inline-block text-center text-white h3 profilebtn" style={{ width: '60px', height: '60px', lineHeight: '40px' }}>PJ</button>
                  </div>
                  <div className="col text-end">
                       
                       <button className="emailbtn">
                         Email me this every Monday morning
                       </button>
                  </div>
                </div>
                <div className='d-flex justify-content-center h3 fw-bold'>
                    <p>Here are your assignments</p>
                </div>
                <div>

      <div className="tab-header d-flex justify-content-center">
        <button
          className= {`tab-button ${activeTab === 'assignments' ? 'active' : ''}`} 
          onClick={() => setActiveTab('assignments')}
        >
          My assignments
        </button>
        <button
          className={`tab-button ${activeTab === 'assigned' ? 'active' : ''}`}
          onClick={() => setActiveTab('assigned')}
        >
          Stuff I've assigned
        </button>
      </div>
      <hr />

      <div className="tab-content d-block mx-auto p-3">
        {activeTab === 'assignments' && (
          <div className='content d-flex justify-content-center align-item-center'>
            
            <p><FontAwesomeIcon icon={faSquareCheck} /> You don’t have any assignments right now <br />
               To-dos and cards assigned to you will show up here.</p>
          </div>
        )}
        {activeTab === 'assigned' && (
          <div className='d-flex justify-content-center'>
            
            <p><FontAwesomeIcon icon={faSquareCheck} /> Nothing assigned yet <br />
               To-dos and cards that you assign to others will show up here.</p>
          </div>
        )}
      </div>
    </div>
    </div>

  )
}


export default Assignment