import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faEllipsisH, faFont, faSmile, faPaperclip, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { FaRegBookmark, FaMicrochip } from 'react-icons/fa6';
import { IoMdPhotos, IoMdPeople } from 'react-icons/io';
import { TbHandClick } from "react-icons/tb";

const Campfire = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openNav = () => {
    setIsOpen(true);
  };

  const closeNav = () => {
    setIsOpen(false);
  };
  return (
    <div className='campfire '>
      <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
        <div className='d-flex justify-content-center align-items-center mt-2'>
          <button className="icon--button me-2">
            <FontAwesomeIcon className='me-1' icon={faTh} />
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <Link to="/project" className="fw-bold">Webnexs HQ</Link>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center camp">
        <div className="card text-center" style={{ width: '75vw' }}>
          <div className="card campfireBody">
            <div className="container campfireBody">
              <div className="row align-items-center">
                <div className="col">
                </div>
                <div className="col text-center">
                  <a href="" className='headcamp text-light fw-bold'> <h3 className="mb-0">Campfire</h3> </a>
                </div>
                <div className="col text-end">
                  <button className="Notifybtn mr-3 text-light">
                    <FontAwesomeIcon icon={faBell} /> Notifying
                  </button>
                  <button className="Notifybtn text-light" onClick={openNav}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body campfireBody" style={{ height: '85vh' }} >
            <div className="text-start">
              <div id="mySidenav" className={`CampfireSidenav ${isOpen ? 'open' : ''}`}>
                <span className="closebtn" onClick={closeNav}>&times;</span>
                <div className="P-2">
                  <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FaRegBookmark /></button>Bookmark</Link>
                </div>
                <div className="P-2">
                  <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><IoMdPhotos /></button>See all files posted here</Link>
                </div>
                <div className="P-2">
                  <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><TbHandClick /></button>Don't forget</Link>
                </div>
                <div className="P-2">
                  <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><IoMdPeople /></button>Notifying 38 people</Link>
                </div>
                <hr className="line" />
                <p className=' sideEvent ps-2 m-0'>For developers</p>
                <div className="P-2">
                  <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FaMicrochip /></button>Configure chatbots</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer campfireBody">
            <input type="text" className='inputfoot' />
            <div className="input-group-append">
              <button className="iconbtn p-2" type="button">
                <FontAwesomeIcon icon={faFont} />
              </button>
              <button className="iconbtn p-2" type="button">
                <FontAwesomeIcon icon={faSmile} />
              </button>
              <button className="iconbtn p-2" type="button">
                <FontAwesomeIcon icon={faPaperclip} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campfire;
