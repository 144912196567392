import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

const ScheduleShow = ({ data }) => {
  return (
    <div className="cardbox">
      <h2 className="card-title text-white fw-bold fs-5 text-center py-2">
        Docs & Files
      </h2>
      <div className="card-body px-3 pt-2 overflow-hidden project-in-card">
        <div className="card-item">
          <div className="d-flex justify-content-center pt-3">
            <div className="calendar-icon bg-warning">
              <FontAwesomeIcon icon={faFile} size="3x" />
            </div>
          </div>
          <p className="fw-normal fs-6 text-secondary text-center"> a centralized place to organize and  share docs, spreadsheets,images and other files</p>
        </div>
      </div>
    </div>
  );
};
export default ScheduleShow;
