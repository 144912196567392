import { Link } from "react-router-dom";
import '../App.css'

const Header =()=>{
    return(
       <div className="">
         <nav className=" d-flex justify-content-between container pt-4">
            <h1 className="text-white">Webnexs</h1>
            <ul className="pt-2">
                <Link to='/signup' className="ps-3 text-decoration-none fs-5 text-white Signup ">Signup</Link>
                <Link to="login" className="ps-3 text-decoration-none fs-5 text-white login">Login</Link>
            </ul>
         </nav>
       </div>
    )
}

export default Header;