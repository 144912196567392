import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { IoIosPlay } from "react-icons/io";

const ProjectSettings = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isCompanyHQ, setIsCompanyHQ] = useState(true);
    const [showNeedle, setShowNeedle] = useState(false);
    const [timesheet, setTimesheet] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log({
            name,
            description,
            isCompanyHQ,
            showNeedle,
            timesheet,
        });
    };
    return (
        <div className='campfire'>
            <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <a href="Webnexs" className="fw-bold">Webnexs HQ</a>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card text-center mb-3" style={{ width: '75vw' }}>
                    <div className="card bg-dark campfireBody ">
                        <div className="container ">
                            <div className="d-flex justify-content-center">
                                <div className=" text-center mt-3">
                                    <a href="CompanyHQ" className='headcamp fw-bold'> <h3 className="mb-0 text-light">Edit details for this Company HQ</h3> </a>
                                </div>
                            </div>
                            <hr className="line" />
                            <div className='ps-5 pe-5'>
                                <form onSubmit={handleSubmit} className="container mt-4 text-start">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label text-light fw-bold">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            className="newquesText"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="description" className="form-label text-light fw-bold">Description (optional)</label>
                                        <input
                                            type="text"
                                            id="description"
                                            className="newquesText"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label text-light fw-bold">What's this used for?</label>
                                        <div className="form-check">
                                            <input
                                                type="radio"
                                                id="project"
                                                name="usage"
                                                className="form-check-input"
                                                checked={!isCompanyHQ}
                                                onChange={() => setIsCompanyHQ(false)}
                                            />
                                            <label htmlFor="project" className="form-check-label text-light">It's for a project</label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="radio"
                                                id="companyHQ"
                                                name="usage"
                                                className="form-check-input"
                                                checked={isCompanyHQ}
                                                onChange={() => setIsCompanyHQ(true)}
                                            />
                                            <label htmlFor="companyHQ" className="form-check-label text-light">It's our Company HQ</label>
                                        </div>
                                    </div>
                                    <div className="switch-container">
                                        <p className='text-light fw-bold mb-0'>Show the needle</p>
                                        <p className="text-light">Move the needle as you make progress.
                                            <a href='https://www.youtube.com/watch?v=D0urtIQrFyQ'>?<IoIosPlay /></a>
                                        </p>
                                        <label className="switch-label">
                                            <div className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={showNeedle}
                                                    onChange={() => setShowNeedle(!showNeedle)}
                                                />
                                                <span className="slider"></span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className='mb-3'>
                                        <p className='text-light fw-bold mb-0'>Timesheet</p>

                                        <p className="text-light">Track time on this project.
                                            <a href='https://www.youtube.com/watch?v=ZlM8yv_K_oI'>?<IoIosPlay /></a>
                                        </p>
                                        <label className="switch-label  ">
                                            <div className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={timesheet}
                                                    onChange={() => setTimesheet(!timesheet)}
                                                />
                                                <span className="slider"></span>
                                            </div>
                                        </label>
                                    </div>
                                </form>
                                <div className='text-start p-2'>
                                    <button type="submit" className="btn btn-success text-light">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectSettings
