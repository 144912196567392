import React, { useRef, useState } from 'react';
import { SiVerizon } from "react-icons/si";
import { FiMonitor } from "react-icons/fi";
import { CiDark, CiLight } from "react-icons/ci";
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';

const Preferences = () => {
    const [timeZone, setTimeZone] = useState('Asia/Kolkata');

    const handleTimeZoneChange = (event) => {
        setTimeZone(event.target.value);
    };

    const currentTime = moment().tz(timeZone).format('YYYY-MM-DD HH:mm:ss');
    const ref = useRef();
    const [selectedDay, setSelectedDay] = useState('');

    const days = [
        { value: '', label: 'Select a day' },
        { value: 'monday', label: 'Monday' },
        { value: 'tuesday', label: 'Tuesday' },
        { value: 'wednesday', label: 'Wednesday' },
        { value: 'thursday', label: 'Thursday' },
        { value: 'friday', label: 'Friday' },
        { value: 'saturday', label: 'Saturday' },
        { value: 'sunday', label: 'Sunday' },
    ];

    const handleChange = (event) => {
        setSelectedDay(event.target.value);
    };
    return (
        <div className='mt-3 preferencesCard '>
            <div className='card-Preferences mb-5'>
                <div className='m-4'>
                    <h1 className='text-light d-flex justify-content-center mb-3'>Preferences</h1>
                    <h3 className='text-light d-flex justify-content-center'>Color Theme</h3>
                    <p className='text-light d-flex justify-content-center'>Choose a color for buttons, menus, and accents</p>
                    <span className='d-flex justify-content-center m-2 mb-4'>
                        <button type="button" className="preferencesBtn bg-light  me-2"><SiVerizon /></button>
                        <button type="button" className="preferencesBtn purpleCl me-2"></button>
                        <button type="button" className="preferencesBtn blueCl me-2"></button>
                        <button type="button" className="preferencesBtn greenCl me-2"></button>
                        <button type="button" className="preferencesBtn orangeCl me-2"></button>
                        <button type="button" className="preferencesBtn charcoalCl me-2"></button>
                    </span>
                    <h3 className='text-light d-flex justify-content-center mb-4'>Appearance</h3>
                    <div className='d-flex justify-content-center'>
                        <button type="button" className="me-2 appearanceBtn">
                            <CiLight className='appearanceIcon' />
                            <p className='text-light'>Always light</p>
                        </button>
                        <button type="button" className="me-2 appearanceBtn">
                            <CiDark className='appearanceIcon' />
                            <p className='text-light'>Always dark</p>
                        </button>
                        <button type="button" className="me-2 appearanceBtn">
                            <FiMonitor className='appearanceIcon' />
                            <p className='text-light'>Same as OS</p>
                        </button>
                    </div>
                    <h3 className='text-light d-flex justify-content-center mt-4'>Date/Time</h3>
                    <div>
                        <p className='text-light m-0'>Time zone</p>
                        <p className='text-light timezoneText'>TeamNex uses your time zone setting for email notifications, event reminders, <a href="#">your profile</a>, and <a href="#">your notification settings</a>.</p>
                        <span className='mb-3'>
                            <select className='col-12 px-2 proinput text-light' id="timezone-select" value={timeZone} onChange={handleTimeZoneChange}>
                                {moment.tz.names().map((tz) => (
                                    <option key={tz} value={tz}>
                                        {tz}
                                    </option>
                                ))}
                            </select>
                        </span>
                        <p className='text-light m-0'>First day of the week</p>
                        <p className='text-light timezoneText'>TeamNex uses this when presenting calendars.</p>
                        <select
                            className='px-2 proinput text-light'
                            id="day-selector"
                            value={selectedDay}
                            onChange={handleChange}
                        >
                            {days.map((day) => (
                                <option key={day.value} value={day.value}>
                                    {day.label}
                                </option>
                            ))}
                        </select>
                        <p className='text-light m-0'>Time format</p>
                        <p className='text-light timezoneText'>TeamNex uses this format when displaying the time.</p>
                        <input
                            className='px-2 proinput text-light col-12'
                            type="date"
                            ref={ref}
                            onChange={(e) => console.log(e.target.value)}
                            onFocus={() => (ref.current.type = "date")}
                            onBlur={() => (ref.current.type = "date")}
                        />
                    </div>
                    <span className='d-flex justify-content-center'>
                        <button type="button" className="text-light bg-success border-2 datetimeBtn mt-4">
                            Save my Date/Time changes
                        </button>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Preferences;
