import React, { useState } from 'react';
import ProfileCard from './ProfileCard';

const ProfileCircles = ({ accounts }) => {
  const [selectedProfile, setSelectedProfile] = useState(null)

  const handleClick = (account) => {
    setSelectedProfile(account);
  };

  const handleCloseCard = () => {
    setSelectedProfile(null);
  };

  return (
    <>

      <div className="profile-container mb-1">
        {accounts.map((account, id) => (
          <div key={id} className="profile-circle " onClick={() => handleClick(account)}>
            {account.profilePic ? (
              <img
                src={account.profilePic}
                alt={account.username}
                className="profile-circle"
              />
            ) : (
              <span className="profile-initials">
                {getInitials(account.username)}
              </span>
            )}
          </div>
        ))}
      </div>

      {selectedProfile &&
        <ProfileCard account={selectedProfile} onClose={handleCloseCard} getInitials={getInitials} />}
    </>

  );
};

const getInitials = (name) => {
  const nameArray = name.split(' ');
  const initials = nameArray.map(n => n.charAt(0)).join('').toUpperCase();
  return initials;
};


export default ProfileCircles;
