import React from 'react'
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';


const Schedule = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className='Schedule container w-75 p-3'>
      <div className='d-flex justify-content-center m-3'>
        <button className="rounded-circle d-inline-block text-center text-white h3 profilebtn" style={{ width: '60px', height: '60px', lineHeight: '40px' }}>PJ</button>
      </div>
      <div className='d-flex justify-content-center h3 fw-bold'>
        <p>Your Schedule</p>
      </div>
      <hr />
      <div className='d-flex justify-content-center'>
        <a href="">Add this Schedule to HEY Calendar, Google Calendar, Outlook, or iCal…</a>
      </div>

      <div className="Calendar-cont d-block mx-auto p-3">
        <div className="card-body text-center">
          <div className="calendar">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              inline
              monthsShown={2}
              calendarClassName="calendar"
            />
            <div className="calendar-footer d-flex justify-content-center">
              <p> <FontAwesomeIcon icon={faCalendarDays} /> {format(selectedDate, 'EEE, MMM d')}</p>
              <p>Nothing's on the schedule</p>
            </div>
            <div>
              <hr />
            </div>
            <a href="#">Show everything up to {format(selectedDate, 'MMM dd')}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Schedule