import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useLocation, useParams, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { RiKey2Fill } from 'react-icons/ri';
import { GiPin, GiThroneKing } from "react-icons/gi";
import axios from 'axios';


const Home = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const location = useLocation();
  // Get userData from location.state, if available
  // const userData = location.state?.userData || null
  const userDataFromState = location.state?.userData || null;
  const storedUserData = localStorage.getItem('userData');
  const userData = userDataFromState || (storedUserData && JSON.parse(storedUserData));
  console.log(userData)

  // const { userData } = location.state || {};
  // console.log(userData.user)
  // console.log(userData)
  // console.log(organizationId)
  // const currentUserData = location.state?.userData || userData;
  const [cookies] = useCookies(['token']);
  const [date, setDate] = useState(new Date());
  const [projects, setProjects] = useState([]);
  const [isValidOrganization, setIsValidOrganization] = useState(true);
  const [loading, setLoading] = useState(true);
  // Safeguard hook for organization validation, always called
  useEffect(() => {
    const token = localStorage.getItem('token') || cookies.token;
    // console.log(token)
    if (!token) {
      navigate('/');
      return;
    }
    const checkOrganizationValidity = async () => {


      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/checkOrganization/${organizationId}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        // Check if the response indicates the organization exists
        if (response.data) {
          // console.log(response.data)
          setIsValidOrganization(true); // Organization exists
        } else {
          setIsValidOrganization(false); // Organization does not exist
        }
      } catch (error) {
        console.error('Error checking organization validity:', error);
        setIsValidOrganization(false); // Mark as invalid if an error occurs (like 404)
      } finally {
        setLoading(false); // Set loading to false when done
      }
    };

    checkOrganizationValidity();
  }, [Navigate, cookies.token, organizationId]);

  // Redirect if the organization is invalid or show loading state

  // Hook to check for the token and fetch projects
  useEffect(() => {
    const token = localStorage.getItem('token') || cookies.token;
    if (!token) {
      navigate('/');
      return;
    }

    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setProjects(response.data.projects);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProjects();
  }, [cookies.token, navigate]);

  // Ensure this is rendered after all hooks have been run


  const onChange = (newDate) => {
    setDate(newDate);
  };
  if (!isValidOrganization) {
    // Redirect to the login page if the organization is invalid
    setTimeout(() => {
      navigate('*'); // Adjust the route to your login page
    }, 1000);
    return <p>Invalid organization ID. Redirecting to the login page...</p>; // Or you can show a message here
  }
  const togglePin = async (id) => {
    const updatedProjects = projects.map((project) =>
      project.id === id ? { ...project, pinned: !project.pinned } : project
    );
    setProjects(updatedProjects);

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/project/${id}`, {
        pinned: updatedProjects.find((project) => project.id === id).pinned,
      });
    } catch (error) {
      console.error("Failed to update pin status:", error);
    }
  };

  return (
    <div className='Home home-main'>
      <div className='head1'>
        <div className="px-2">
          <Link to="adminland" className="px-2 d-flex justify-content-end adminlandText">
            <RiKey2Fill className='mt-1' style={{ fontSize: "15px" }} />
            Adminland
          </Link>
        </div>
        <h1 className='d-flex justify-content-center text-white fw-bold Organization-name'>
          {/* {userData ? userData.user.organizationName : " Organization"} */}
          {userData?.user.organizationName || "Organization"}
        </h1>
      </div>
      <div className="head2 d-flex justify-content-center mt-3">
        <Link className='act-btn' to="project/new">
          <button className='px-4 py-2 mx-3 action-btn'>
            <span className='fw-normal '>Make a new project</span>
          </button>
        </Link>
        <Link className='act-btn' to='/invite-people'>
          <button className=' px-4 py-2 action-btn'>
            <span className='fw-normal '>Invite People</span>
          </button>
        </Link>
      </div>
      <div className="d-flex justify-content-center mt-3 col-sm-12">
        <p className='p-tag'>
          <span className='text-white'>Pinned & recent projects below &middot; </span>
          <span><Link to="projects/directory" className="mx-1 text-white">View all in a list</Link></span>
          <span>&middot; </span>
          <span><Link to="view-template" className="mx-1 text-white">View templates</Link></span>
          <span className='text-white kbd'>&middot; Press
            <Link to="quickaccess" className='text-decoration-none'> <kbd>Ctrl + J</kbd></Link>
            anytime to jump</span>
        </p>
      </div>
      <div className=" mt-5">
        <div className=" d-flex flex-column flex-md-row flex-wrap justify-content-center mt-4">
          {/* {projects.map((project) => (
            <Link
              to={{
               pathname: `/project/${project.id}`,
                state: { tools: project.tools }, // Pass necessary state
              }}
              className="text-decoration-none"
              key={project.id}
            >
              <div className="card announcement-card m-2 w-md-auto">
                <div className="homecard-body p-3">
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                    <div className="w-100">
                      <div className="badge bg-warning text-white mb-2">
                        {project.category}
                      </div>
                      <h5 className="card-title text-white">{project.name}</h5>
                      <p className="card-text text-white">{project.description}</p>
                    </div>
                    <div className="d-flex justify-content-end mt-2 mt-md-0">
                      <button
                        className="pin-button"
                        onClick={(e) => {
                          e.preventDefault(); // Prevents link navigation when pin button is clicked
                          togglePin(project.id); // Ensure project.id is correctly passed
                        }}
                      >
                        {project.pinned ? <GiPin className="color-icon" /> : <GiPin className="pin-icon" />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))} */}
          <hr className="line" />
          <span className="label text-center recently-visited">RECENTLY VISITED</span>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row flex-wrap justify-content-center mt-0">
        {projects.map((project) => (
          <Link
            to={{
              pathname: `project`, // Ensure this is consistent
              state: { tools: project.tools },
            }}

            className="text-decoration-none"
            key={project.id}
          >
            <div className="card announcement-card m-2 w-md-auto">
              <div className="homecard-body p-3">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div className="w-100">
                    <div className="badge bg-warning text-white mb-2">
                      {project.category}
                    </div>
                    <h5 className="card-title text-white">{project.name}</h5>
                    <p className="card-text text-white">{project.description}</p>
                  </div>
                  <div className="d-flex justify-content-end mt-2 mt-md-0">
                    <button
                      className="pin-button"
                      onClick={(e) => {
                        e.preventDefault(); // Prevents link navigation when pin button is clicked
                        togglePin(project.id); // Ensure project.id is correctly passed
                      }}
                    >
                      {project.pinned ? <GiPin className="color-icon" /> : <GiPin className="pin-icon" />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="container mt-5 mb-5 d-flex justify-content-center HomeCardCont">
        <div className="card card1 schedule-assignment">
          <div className="card-header bg-transparent border-0 text-center">
            <div className="schedule-header">
              <span>YOUR SCHEDULE</span>
            </div>
          </div>
          <div className="card-body text-center">
            <div className="calendar mt-2">
              <Calendar
                className="text-light bg-dark calendar"
                onChange={onChange}
                value={date}
              />
              <div className="calendar-footer d-flex justify-content-between mt-3">
                <p className='text-light'>
                  <FontAwesomeIcon className='text-warning me-2' icon={faCalendarDays} />
                  {date.toDateString()}
                </p>
                <p className='text-light'>Nothing's on the schedule</p>
              </div>
            </div>
          </div>
        </div>
        <div className="card card1 schedule-assignment">
          <div className="card-header bg-transparent border-0 text-center">
            <div className="assignments-header">
              <span>YOUR ASSIGNMENTS</span>
            </div>
          </div>
          <div className="card-body text-center mt-5">
            <FontAwesomeIcon icon={faCircleCheck} className="home-shedule" />
            <p className="mt-3 text-secondary">
              You don’t have any assignments right now. To-dos and cards assigned to you will show up here.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;