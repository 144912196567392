import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo1 from "../asset/Logo-g.png";
import axios from "axios";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login = ({ onSignIn }) => {
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [eyePassword, setEyePassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://basecamp-admin.webnexs.org/api/login`, formData);
      // const response = await axios.post(`http://localhost:3334/api/login`, formData);
      localStorage.setItem('token', response.data.token);  // Save token to localStorage
      localStorage.setItem('userData', JSON.stringify(response.data.dataa));
      toast.success('Logged in successfully!');
      console.log('Logged in successfully:', response.data.dataa);
      onSignIn();  // Call the onSignIn function to update the state
      // navigate('/');
      navigate('/', { state: { userData: response.data.dataa } });
    } catch (error) {
      console.log('API URL:', process.env.REACT_APP_API_URL);
      console.error('Login error:', error.message);
      if (error.response && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('An error occurred. Please try again later.');
      }
    }
    setFormData({
      email: "",
      password: "",
    });
  };

  const handleNextClick = () => {
    setShowPassword(true);
  };

  // new ---------------------->
  const handleGoogleLogin = () => {
    window.location.href = `https://basecamp-admin.webnexs.org/auth/google`;
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-centert">
        <div className="Signin text-center" style={{ maxWidth: "448px", width: "100%" }}>
          <img src='https://imagedelivery.net/r89jzjNfZziPHJz5JXGOCw/1dd59d6a-7b64-49d7-ea24-1366e2f48300/public' alt="Logo" className="d-block m-auto" style={{ width: "100px" }} />
          <div className="card p-2 mt-4 shadow-sm">
            <div className="card-body">
              {/* <Link to="/GoogleSignin" className="text-decoration-none"> */}
              <h1 className="fs-5 pb-2 text-white">Log in to TeamNex</h1>
              <button onClick={handleGoogleLogin} type="submit" className="action_button">
                <img width="28" height="28" className="action_button__img" alt="Google" title="Google" src={logo1} />
                Sign in with Google
              </button>
              {/* </Link> */}
              <div className="d-flex align-items-center my-3">
                <hr className="flex-grow-1" />
                <span className="mx-2  text-white">Or, use my email address</span>
                <hr className="flex-grow-1" />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  {/* <label className="label" htmlFor="email">Email or username</label> */}
                  <input
                    className="form-control mb-2"
                    type="email"
                    placeholder="Your email (e.g. julie.young@hey.com)"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                {!showPassword && (
                  <button type="button" className="action_button" onClick={handleNextClick}>
                    Next
                  </button>
                )}
                {showPassword && (
                  <div>
                    <div className="form-group position-relative">
                      <label className="label" htmlFor="password">Enter password</label>
                      <input
                        type={eyePassword ? 'text' : 'password'}
                        className="form-control mb-2"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                      {/* <span
                        type="button"
                        className=" position-absolute end-0 top-0 mt-5 me-2"
                        onClick={() => setEyePassword(!eyePassword)}
                      >
                        <FontAwesomeIcon icon={eyePassword ? faEye : faEyeSlash} />
                      </span> */}
                    </div>
                    <button type="submit" className="btn btn-success custom-btn btn-block mt-3 w-100">
                      Login
                    </button>
                  </div>
                )}
                {error && <p className="text-danger mt-2">{error}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
      <p className="text-center pt-2">
        <Link to="/forgot-password"> Forgot your password</Link>
      </p>
    </>
  );
};

export default Login;
