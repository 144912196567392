import React, { useState } from "react";

const ProfileCard = ({ account, onClose, getInitials }) => {


    return (

        <div className="card profileCard" >
            <button type="button" class="btn-close profileCloseBtn" onClick={onClose} aria-label="Close"></button>
            {account.profilePic ?
                <img src={account.profilePic} className="card-img-top profilePic" alt="..."></img>
                :
                // <img src={ } className="card-img-top profilePic" alt="..."></img>
                <span className="profilePic profile-circle">
                    {getInitials(account.username)}
                </span>
            }
            <div className="card-body text-center">
                <h5 className="card-title">{account.username}</h5>
                <p className="card-text m-0">{account.role} at</p>
                <p className="card-text m-0">{account.companyName}</p>
                <p className="card-text m-0">{account.location}</p>
                <p className="card-text">time</p>
                <div className="btn-group profileBtnGroup text-dark">
                    <a href="#" className="btn active text-dark rounded text-decoration-none profileBtnActive" aria-current="page">Ping {account.username}</a>
                    <a href="#" className="btn text-dark rounded profileHoverBtn text-decoration-none">See what I've been up to</a>
                    <a href="#" className="btn text-dark rounded profileHoverBtn text-decoration-none">Edit my profile</a>
                </div>
            </div>
        </div>



    )
}
export default ProfileCard