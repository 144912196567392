const AddEmployees = () => {
  return (
    <div className="">
      <h1 className="fs-1 fw-bold text-white text-center">
        Who are you inviting?
      </h1>
      <p className="text-white fw-normal text-center">
        First you’ll invite them to the account. Then you can add them to
        projects.
      </p>
      <div className="invite-emp">
        <form>
          <div className="card-container p-5">
            <div className="form-check pb-2">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"

              />
              <label
                className=" text-white  fw-bold fs-4 "
                for="flexRadioDefault1"
              >
                Anyone from webnexs.com can see this project
              </label><br />
              <span className="text-secondary">People who work at webnexs.com are the only people who can create projects, add others to projects, and act as administrators. Add them this way if they are full-time, part-time, or a volunteer at webnexs.com.</span>
              <br />
            </div>
            <div className="form-check py-2">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label
                className=" text-white  fw-bold fs-4 "
                for="flexRadioDefault1"
              >
                An outside collaborator, partner, contractor, guest, etc.
              </label><br />
              <span className="text-secondary">This person doesn’t work at webnexs.com. They can collaborate on projects with you, but they won’t be able to create projects, invite people to the account, add people to projects, or be admins.</span>
              <br />
            </div>
            <div className="form-check py-2">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label
                className=" text-white  fw-bold fs-4 "
                for="flexRadioDefault1"
              >
                A client you’re doing work for
              </label><br />
              <span className="text-secondary">Clients can access projects you’ve created, but they can’t create their own, invite or add new people, or become admins. You can hide parts of projects from them so they can’t see work in progress.</span>
              <br />
            </div>
          </div>
          <p className="text-center"><button className="Projectbtn">Next, enter their name</button></p>
        </form>
      </div>
    </div>
  );
};

export default AddEmployees;
