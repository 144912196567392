import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests

const ForgotPass = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://basecamp-admin.webnexs.org/api/forgot-password', { email });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response.data.message);
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center  Forgot">
            <div className='Loginpage text-center' style={{ maxWidth: '448px', width: '100%' }}>

                <div className="card p-3 mt-4 shadow-sm">
                    <div className="card-body">
                        <h2 className="h5 mt-0 mb-2 text-center text-white fw-bold">Forgot your Password</h2>
                        <p className="mt-0 mb-2 fw-normal text-white">Enter your email address below and we’ll send you password reset instructions</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control mb-2"
                                    name="email"
                                    placeholder="Enter your email address..."
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-success btn-block mt-3 w-100 fw-bold">
                                Email me reset instructions
                            </button>
                        </form>
                        {message && <p className="mt-3 text-center">{message}</p>}
                    </div>
                </div>
                <p className="text-center mt-3">
                    <Link to="/login">Never mind, Go Back</Link>
                </p>
            </div>
        </div>
    );
};

export default ForgotPass;
