import React, { useState } from 'react';
import MessageBoardShow from "../ProjectPage/MessageBoard/MessageBoardShow";
import CampfireShow from "../ProjectPage/CampfireShow";
import ScheduleShow from "../ProjectPage/ScheduleShow";
import TodoShow from "../ProjectPage/TodoShow";
import AutocheckinShow from "../ProjectPage/AutocheckinShow";
import DocumentShow from "../ProjectPage/DocumentShow";
import EmailForwardShow from "../ProjectPage/EmailForwardShow";
import CardTableShow from "../ProjectPage/CardTableShow";
import ProfileCircles from "./ProfileCircles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { BiSolidArchiveIn } from "react-icons/bi";
import { FaBellSlash, FaBoxesStacked } from "react-icons/fa6";
import { BsPinAngleFill } from "react-icons/bs";
import { FiGlobe } from "react-icons/fi";
import { IoIosSettings, IoMdPeople } from "react-icons/io";
import { Link, useLocation } from 'react-router-dom';

const items = [
  {
    username: "Arun Pandiyan",
    profilePic: '',
    title: "Title 1",
    paragraph: "this is sample paragraph to check the messageboard in TeamNex truncation functionality in the message board"
  },
  {
    username: "Pragatheeshwaran",
    profilePic: 'photoo.JPG',
    title: "Title 2",
    paragraph: "Another example paragraph to demonstrate the truncation functionality in the message board."
  },
  {
    username: "balayukesh",
    profilePic: '',
    title: "Title 3",
    paragraph: "This paragraph is short."
  },
  {
    username: "User Four",
    profilePic: 'photoo.JPG',
    title: "Title 4",
    paragraph: "This is another sample paragraph for the message board."
  }
];

const campfireShow = [
  {
    username: "Arun Pandiyan",
    profilePic: 'photoo.JPG',
    title: "Important Marketing Checklists:",
    paragraph: "this is sample paragraph to check the messageboard in TeamNex truncation functionality in the message board"
  },
  {
    username: "Pragatheeshwaran",
    profilePic: 'pic2o.png',
    title: "Guide To Social Media Image Dimensions",
    paragraph: "Another example paragraph to demonstrate the truncation functionality in the message board."
  },
  {
    username: "balayukesh",
    profilePic: 'phooto.JPG',
    title: "Kicking off our HQ",
    paragraph: "Please check for these texts in webnexs."
  },
  {
    username: "User Four",
    profilePic: 'photo.png',
    title: "	Kicking off our HQ",
    paragraph: "This is another sample paragraph for the message board."
  }
];

const acc = [
  {
    id: 1,
    username: "Prakash Kumar",
    profilePic: 'photo.JPG',
    companyName: "Webnexs",
    role: "Web Developer",
    location: "Banglore"
  },
  {
    id: 2,
    username: "Balaji",
    profilePic: null,
    companyName: "Webnexs",
    role: "Web Developer",
    location: "Kerala"
  },
  {
    id: 3,
    username: "Arun Kmumar",
    profilePic: null,
    companyName: "Webnexs",
    role: "Web Developer",
    location: "chennai"
  }
];

const ProjectCard = ({ userData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const currentUserData = location.state?.userData || userData;

  const openNav = () => {
    setIsOpen(true);
  };
  const closeNav = () => {
    setIsOpen(false);
  };
  return (
    <div className='border container project'>
      <div>
        <div id="mySidenav" className={`sidenav ${isOpen ? 'open' : ''}`}>
          <span className="closebtn" onClick={closeNav}>&times;</span>
          <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><BsPinAngleFill /></button>Pin to Home</Link>
          </div>
          <div className="P-2">
            <Link to="/justfollow" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FaBellSlash /></button>Switch to just following</Link>
          </div>
          <div className="P-2">
            <Link to="/projectSettings" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><IoIosSettings /></button>Edit project settings</Link>
          </div>
          <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FaBoxesStacked /></button>Set up tools</Link>
          </div>
          <div className="P-2">
            <Link to="/setup-people" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><IoMdPeople /></button>Set up people</Link>
          </div>
          <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><BiSolidArchiveIn /></button>Archive or delete</Link>
          </div>
          <hr className="line" />
          <p className=' sideEvent ps-2 m-0'>For developers</p>
          <div className="P-2">
            <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FiGlobe /></button>Set up webhooks</Link>
          </div>
        </div>
      </div>
      <div className='projectheader' data-behavior="sortable_cards">
        <div className="d-flex justify-content-end">
          <button className="projeccardtBtn text-light mt-2" onClick={openNav}>
            <FontAwesomeIcon icon={faEllipsisH} />
          </button>
        </div>
        <h3 className='text-center fs-4 fw-bold text-white headcamp'>{currentUserData}</h3>
        <h4 className='text-white fs-6 fw-normal text-center'>
          Company-wide announcements and stuff everyone needs to know.
        </h4>
        <div className="d-flex flex-column d-flex justify-content-center flex-md-row setupbtn">
          <Link to="/setup-people" className="text-white text-decoration-none sbtn pt-2">Set up people</Link>
          <div className="d-flex justify-content-center justify-content-md-start mt-2 ps-md-4">
            <ProfileCircles accounts={acc} />
          </div>
        </div>
      </div>
      <div className="projectbody row d-flex justify-content-center">
        <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center my-2">
          <Link to="/chat" className="text-decoration-none">
            <CampfireShow data={campfireShow} />
          </Link>
        </div>
        <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center my-2">
          <Link to="/messageBoard" className="text-decoration-none">
            <MessageBoardShow data={items} />
          </Link>
        </div>
        <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center my-2">
          <Link to="/ProjectSchedule" className="text-decoration-none">
            <ScheduleShow />
          </Link>
        </div>
        <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center my-2">
          <Link to="/toDos" className="text-decoration-none">
            <TodoShow />
          </Link>
        </div>
        <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center my-2">
          <Link to="/autocheckin" className="text-decoration-none">
            <AutocheckinShow data={items} />
          </Link>
        </div>
        <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center my-2">
          <Link to="/docsFiles" className="text-decoration-none">
            <DocumentShow />
          </Link>
        </div>
        <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center my-2">
          <Link to="/emailForward" className="text-decoration-none">
            <EmailForwardShow />
          </Link>
        </div>
        <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center my-2">
          <Link to="/cardTable" className="text-decoration-none">
            <CardTableShow />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
