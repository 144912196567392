import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTh, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaPlus } from "react-icons/fa";
import ProfileCircles from './ProfileCircles';
import { Link } from 'react-router-dom';
import { FaRegBookmark } from 'react-icons/fa6';

function Autocheckin() {
    const acc = [
        {
            username: "Arun Pandiyan",
            profilePic: null
        },
        {
            username: "Pragatheeshwaran",
            profilePic: null
        },
        {
            username: "Arun Kmumar",
            profilePic: null
        }
    ];
    const [isOpen, setIsOpen] = useState(false);

    const openNav = () => {
        setIsOpen(true);
    };

    const closeNav = () => {
        setIsOpen(false);
    };
    return (
        <div className='campfire'>
            <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <button className="icon--button me-2">
                        <FontAwesomeIcon className='me-1' icon={faTh} />
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                    <Link to="/project" className="fw-bold">Webnexs HQ</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center camp">
                <div className="card text-center" style={{ width: '75vw' }}>
                    <div className="card bg-dark campfireBody">
                        <div className="container ">
                            <div className="d-flex justify-content-between">
                                <div className=" text-start">
                                    <Link to="/Autocheckin-NewQuestion" className='text-decoration-none'>
                                        <button className="newEvent px-2 mt-3 py-1 text-dark">
                                            <FaPlus className='me-1' />
                                            New Question
                                        </button>
                                    </Link>
                                </div>
                                <div className=" text-center mt-3">
                                    <a href="project" className='headcamp'> <h3 className="mb-0 text-light fw-bold">Automatic Check-ins</h3> </a>
                                </div>
                                <div className=" text-end">
                                    <button className="Notifybtn text-light" onClick={openNav}>
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </button>
                                </div>
                            </div>
                            <hr className="line" />
                        </div>
                        <div className="text-start">
                            <div id="mySidenav" className={`CampfireSidenav checkinSidenav ${isOpen ? 'open' : ''}`}>
                                <span className="closebtn" onClick={closeNav}>&times;</span>
                                <div className="P-2">
                                    <Link to="#" className='sideBar text-decoration-none'> <button className='me-2 sidebarBtn'><FaRegBookmark /></button>Bookmark</Link>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-5'>
                            <div className="card" style={{ width: '65vw' }}>
                                <div className="card bg-dark campfireBody">
                                    <Link className='text-decoration-none'>
                                        <div className="container p-3">
                                            <p className='text-light weekdayTime text-start m-0'>Asking 31 people every weekday at 6:30pm.</p>
                                            <h3 className='text-light text-start'>What was that on your plate today?</h3>
                                            <ProfileCircles accounts={acc} />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-5'>
                            <div className="card" style={{ width: '65vw' }}>
                                <div className="card bg-dark campfireBody">
                                    <Link className='text-decoration-none'>
                                        <div className="container p-3">
                                            <p className='text-light weekdayTime text-start m-0'>Asking 31 people every weekday at 6:30pm.</p>
                                            <h3 className='text-light text-start'>What was that on your plate today?</h3>
                                            <ProfileCircles accounts={acc} />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-5'>
                            <div className="card" style={{ width: '65vw' }}>
                                <div className="card bg-dark campfireBody">
                                    <Link className='text-decoration-none'>
                                        <div className="container p-3">
                                            <p className='text-light weekdayTime text-start m-0'>Asking 31 people the first Monday of each month at 9am.</p>
                                            <h3 className='text-light text-start'>Read any good books lately?</h3>
                                            <ProfileCircles accounts={acc} />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-5 mb-5'>
                            <div className="card" style={{ width: '65vw' }}>
                                <div className="card bg-dark campfireBody">
                                    <Link className='text-decoration-none'>
                                        <div className="container p-3">
                                            <p className='text-light weekdayTime text-start m-0'>This check-in is paused, nobody is being asked.</p>
                                            <h3 className='text-light text-start'>Been inspired by anything lately?</h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Autocheckin;
