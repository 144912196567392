import React from 'react'
import { RiKey2Fill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";


const ChangeMessageCategories = () => {
    const categories = [
        { icon: '📢', name: 'Announcement' },
        { icon: '✨', name: 'FYI' },
        { icon: '❤️', name: 'Heartbeat' },
        { icon: '💡', name: 'Pitch' },
        { icon: '🔥', name: 'Question' },
    ];
    return (
        <div>
            <div className='campfire'>
                <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
                    <div className='d-flex justify-content-center align-items-center mt-2'>
                        <Link to="/adminland" className="fw-bold"><RiKey2Fill style={{
                            fontSize: "20"
                        }} />Adminland</Link>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center camp">
                    <div className="card " style={{ width: '75vw' }}>
                        <div className="card bg-dark campfireBody">
                            <div className="container ">
                                <div className=" text-center mt-3">
                                    <a href="message" className='headcamp fw-bold'> <h3 className="mb-0 text-light">Change message categories</h3> </a>
                                </div>
                                <hr className="line" />
                            </div>
                            <div className="card-body bg-dark p-0">
                                <div className="admin-panel text-light">
                                    <div className="categories-container">
                                        <p>Message categories help create structure and give people an idea of what kinds of things to post. <a href="example">See an example...</a></p>
                                        <p>These default categories are used to populate new projects. They can be changed afterwards within each project.</p>
                                        <p><b>Note:</b> Changes you make below WILL NOT apply to existing projects, project templates, or teams. Any categories you add will only be used in new teams and projects, and projects created from new templates, going forward.</p>
                                        <div className="categories-list">
                                            {categories.map((category, index) => (
                                                <div key={index} className="category-item">
                                                    <span className="category-icon">{category.icon}</span>
                                                    <span className="category-name">{category.name}</span>
                                                    <span className="category-actions">
                                                        <button className="edit-btn text-light"><MdEdit/></button>
                                                        <button className="delete-btn text-light"><RiDeleteBin6Fill/></button>
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                        <button className="add-category-btn">Add a category</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangeMessageCategories
