import React, { useState } from 'react';

const QuickAccess = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [recentlyVisited, setRecentlyVisited] = useState([
    { id: 1, name: 'TeamNex', type: 'project' },
    { id: 2, name: 'Schedule - TeamNex', type: 'schedule' },
    { id: 3, name: 'Message Board - TeamNex', type: 'message' },
    // Add more items as needed
  ]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleItemClick = (item) => {
    // Logic to navigate to the selected item
    console.log(`Navigating to: ${item.name}`);
  };

  const filteredItems = recentlyVisited.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="quick-access">
      <input
        type="text"
        placeholder="Jump to a project, person, or recently visited page..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <ul className="dropdown-menu">
        {filteredItems.map((item) => (
          <li className='text-light' key={item.id} onClick={() => handleItemClick(item)}>
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuickAccess;
