import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProfileForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    title: '',
    location: '',
    bio: '',
    email: '',
    profilePhoto: '', // Add profilePhoto to the formData state
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      setError(null);
  
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get('https://basecamp-admin.webnexs.org/api/profile', {
          headers: {
            'Authorization': `Bearer ${token}`, // Include token in header
          },
        });
        setFormData(response.data);
      } catch (err) {
        setError(err.response ? err.response.data.message : 'Something went wrong');
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (files) {
      setSelectedFile(files[0]);
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
  
    const form = new FormData();
    form.append('name', formData.name);
    form.append('title', formData.title);
    form.append('location', formData.location);
    form.append('bio', formData.bio);
    form.append('email', formData.email);
  
    if (selectedFile) {
      form.append('profilePhoto', selectedFile);
    }
  
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.post('https://basecamp-admin.webnexs.org/api/register', form, {
        headers: {
          'Authorization': `Bearer ${token}`, // Include token in header
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Assuming the response contains the URL of the uploaded photo
      setFormData({ ...formData, profilePhoto: response.data.profilePhoto });
    } catch (err) {
      setError(err.response ? err.response.data.message : 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container profileCard mt-5 d-flex justify-content-center align-items-center">
      <div className="card" style={{ width: '33rem' }}>
        <div className="card-body profileForm">
          <form onSubmit={handleSubmit}>
            <div className="text-center mb-4">
              <div
                className="rounded-circle bg-success text-white d-inline-block"
                style={{ width: '80px', height: '80px', lineHeight: '80px', fontSize: '36px' }}
              >
                {formData.profilePhoto ? (
                  <img
                    src={formData.profilePhoto}
                    alt="Profile"
                    style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                  />
                ) : (
                  'AK'
                )}
              </div>

              <div className="mt-2">
                <input
                  type="file"
                  name="profilePhoto"
                  onChange={handleChange}
                  className="photobutton text-light"
                  id="profilePhoto"
                  style={{ display: 'none' }} // Hide the default input
                />
                <label htmlFor="profilePhoto" className="text-light photobutton">
                  Upload a photo...
                </label>
              </div>

              <div className="mt-3 photobutton2 d-block m-auto w-50 fs-6 textRequired">
                Upload a photo to show your 😊
              </div>
            </div>

            {/* Form Fields */}
            <div className="form-group">
              <label htmlFor="name" className="h5 fw-bold text-light">
                Name <span className="textRequired h6">required</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="title" className="h5 fw-bold text-light mt-3">
                Title at webnexs.com
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                value={formData.title}
                onChange={handleChange}
                placeholder=""
              />
            </div>
            <div className="form-group">
              <label htmlFor="location" className="h5 text-light fw-bold mt-3">
                Location
              </label>
              <input
                type="text"
                className="form-control"
                id="location"
                value={formData.location}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="bio" className="h5 text-light fw-bold mt-3">
                Short bio or current status
              </label>
              <input
                type="text"
                className="form-control"
                id="bio"
                value={formData.bio}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" className="h5 text-light fw-bold mt-3">
                Email address <span className="textRequired h6">required</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            {/* Save Button */}
            <button type="submit" className="w-100 d-block m-auto p-2 mt-3 savebutton" disabled={loading}>
              {loading ? 'Saving...' : 'Save my changes'}
            </button>
          </form>

          {/* Error Message */}
          {error && <div className="alert alert-danger mt-3">{error}</div>}

          <hr />

          {/* Leave Webnexs Section */}
          <label htmlFor="login-info" className="h6 fw-bold mt-3 text-light">
            Want to leave the webnexs.com TeamNex account?
          </label>
          <div className="mt-1">
            <a href="#">Remove me from this account...</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
