import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faStopwatch} from '@fortawesome/free-solid-svg-icons';
import { Form, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import DatePicker from 'react-datepicker';


const TimeSheet = () => {
    const [isClicked, setIsClicked] = useState(false);

    const handleDdboxClick = () => {
        setIsClicked(!isClicked);
    };

    const [startDate, setStartDate] = useState(new Date('2024-06-10'));
    const [endDate, setEndDate] = useState(new Date('2024-07-10'));

  return (
    <div className='TimeSheet container p-3 w-75'>
        <div className='d-flex justify-content-center align-item-center fw-bold h2 pt-2'>
            <p>Timesheets</p>
        </div>
        <hr />
        <div className='show-content d-flex justify-content-center'>
            <div className='boxx p-1'> Everyone </div>
            <div className='boxx p-1'> All projects </div>
            <div className='boxx p-1'> Jun 10–Jul 10 </div>
            <div className='p-1'>
                <Link to='' onClick={handleDdboxClick}>Change</Link>
            </div>
        </div>

        <div className="Timetab-content d-block mx-auto w-50 p-3 mt-5">
            <div className='content d-flex justify-content-center align-item-center'>
            <p className='mt-2 h5'> <FontAwesomeIcon className='me-3  Bmicon' icon={faStopwatch} /> 
                 <strong>You haven’t bookmarked anything yet</strong></p>
            </div>
        </div>

{/* -------------------------------------------------------- */}
      {isClicked && (
        <div className='boxclick d-block mx-auto p-3'>
            <div>
                <p className='fw-bold text-success ps-5'>Show Timesheets...</p>
            </div>
            <div className='forms'>
                <Form.Group as={Row} className="mb-3">
                   <Form.Label column sm="2">for</Form.Label>
                  <Col sm="10">
                    <Form.Select>
                       <option>Everyone</option>
                       <option>Option 1</option>
                       <option>Option 2</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                   <Form.Label column sm="2">on</Form.Label>
                  <Col sm="10">
                    <Form.Select>
                       <option>All Projects</option>
                       <option>Option 1</option>
                       <option>Option 2</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">from</Form.Label>
                 <Col sm="10" className="d-flex">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="MMM dd, yyyy"
                        className="form-control me-2"
                       />
                     <span className='pt-2 ms-2 me-1'>to</span>

                     <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="MMM dd, yyyy"
                        className="form-control ms-2"
                     />

                  </Col>
                </Form.Group>
            </div>
            <div className='bottom_btn d-flex justify-content-between'>
                   <div className='d-flex '>
                    <button className='ms-5 filter-btn'>filter</button>
                    <button className='ms-2 cancel-btn '>cancel</button>
                   </div>
                   <div>
                    <button className='reset-btn pt-2'>reset</button>
                   </div>
            </div>

        </div>
        )}

    </div>
  )
}

export default TimeSheet