import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableCellsLarge,faListUl,faPlus} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { FaListUl } from "react-icons/fa";

const View_Template = () => {
    const [activeTemplate, setActiveTemplate] = useState('project');

  return (
    
    <div className='View_Template'>

        <div className='d-flex justify-content-center'>
            <button className={`top-btn p-2 me-2 ${activeTemplate === 'project' ? 'active' : ''}`}
            onClick={() => setActiveTemplate('project')}>
             <FontAwesomeIcon className='icons' icon={faTableCellsLarge} /> Project Templates
            </button>
            <button  className={`top-btn p-2 ${activeTemplate === 'list' ? 'active' : ''}`}
             onClick={() => setActiveTemplate('list')}>
            <FontAwesomeIcon className='icons' icon={faListUl} /> To-do List Templates
            </button>
        </div>

        <div className='container w-75 mt-2 p-3'>

        {activeTemplate === 'project' && (
            <div className='project-temp'>
            <h2 className='d-flex justify-content-center align-item-center fw-bold m-3 text-center'>Project Templates</h2>
            <p className='d-flex justify-content-center text-center m-3'>Save yourself time by creating project templates with frequently-used tools, to-do lists,<br /> files, and more. Anyone on your account who can create projects can use and edit these <br /> templates.</p>
            <div className=' d-flex justify-content-center'>
              
                <button className='box-btn'>
                  <Link to='/Project_Template' className='box-btn'>
                   <p className='text-decoration-none'><FontAwesomeIcon className='text-success h2 fw-bold' icon={faPlus} /> <br /> <span className='text-white'>New Template</span></p>
                  </Link>
                </button>
              
            </div>
            </div>
          )}
          
          {activeTemplate === 'list' && (
            <div className='List-temp'>
            <h2 className='d-flex justify-content-center align-item-center fw-bold m-3 text-center'>To-do List Templates</h2>
            <p className='d-flex justify-content-center text-center m-3'>Save yourself time by creating to-do templates you can drop into any project. Everyone on <br /> your account can edit and create their own lists from these templates.</p>
            <div className=' d-flex justify-content-center'>
                <button className='box-btn'>
                  <Link to='/ToDo_List_Template'>
                   <p><FontAwesomeIcon className='text-success h2 fw-bold' icon={faPlus} /> <br /> <span className='text-white'>New Template</span></p>
                   </Link>
                </button>
            </div>
            </div>
            )}

        </div>
    </div>
  )
}

export default View_Template