import { SiVerizon } from "react-icons/si";
const TodoShow = () => {
    return (
        <div className="cardbox">
            <h2 className="card-title text-white fw-bold fs-5 text-center py-2">To-dos</h2>
            <div className="card-body px-3 pt-2 overflow-hidden project-in-card">
                <div className="card-item">
                    <div className="d-flex justify-content-center pt-3">
                        <div className="calendar-icon bg-primary">
                            <SiVerizon className='fs-1' />
                        </div>
                    </div>
                    <p className="fw-normal fs-6 text-secondary text-center">
                        Organize work across teams. Assign tasks, set due dates, and discuss.</p>
                </div>
            </div>
        </div>
    )
}
export default TodoShow;