import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { RiKey2Fill } from 'react-icons/ri';
import { GiPin } from "react-icons/gi";
import axios from 'axios';

const Home = ({ userData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserData = location.state?.userData || userData;
  const [cookies] = useCookies(['token']);
  const [date, setDate] = useState(new Date());
  const [projects, setProjects] = useState([]);

  const onChange = (newDate) => {
    setDate(newDate);
  };

  const togglePin = async (id) => {
    const updatedProjects = projects.map((project) =>
      project.id === id ? { ...project, pinned: !project.pinned } : project
    );
    setProjects(updatedProjects);
    // Optional: Persist the pin status change to the server
    try {
      await axios.patch(`https://basecamp-admin.webnexs.org/api//project/:id/${id}`, {
        pinned: updatedProjects.find((project) => project.id === id).pinned,
      });
    } catch (error) {
      console.error("Failed to update pin status:", error);
    }
  };
  useEffect(() => {
    const token = localStorage.getItem('token') || cookies.token;
    if (!token) {
      navigate('/login');
      return;
    }
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://basecamp-admin.webnexs.org/api/projects', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setProjects(response.data.projects);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProjects();
  }, [cookies.token, navigate]);
  return (
    <div className='Home'>
      <div className='head1'>
        <div className="px-2 d-flex justify-content-end">
          <Link to="Adminland" className="px-2 d-flex justify-content-end adminlandText">
            <RiKey2Fill className='mt-1' style={{ fontSize: "15px" }} />
            Adminland
          </Link>
        </div>
        <h1 className='d-flex justify-content-center text-white'>{currentUserData}</h1>
      </div>
      <div className="head2 d-flex justify-content-center mt-3">
        <Link className='act-btn' to="/createproject">
          <button className='btn-success px-4 py-2 mx-3 action-btn'>
            <span className='fw-normal text-white'>Make a new project</span>
          </button>
        </Link>
        <Link className='act-btn' to='/Invite_People'>
          <button className='btn-success px-4 py-2 action-btn'>
            <span className='fw-normal text-white'>Invite People</span>
          </button>
        </Link>
      </div>
      <div className="d-flex justify-content-center mt-3 col-sm-12">
        <p className='p-tag'>
          <span className='text-white'>Pinned & recent projects below &middot; </span>
          <span><Link to="/view_All_List" className="mx-1 text-white">View all in a list</Link></span>
          <span>&middot; </span>
          <span><Link to="/View_Template" className="mx-1 text-white">View templates</Link></span>
          <span className='text-white kbd'>&middot; Press
            <Link to="/quickAccess" className='text-decoration-none'> <kbd>ctrl + J</kbd></Link>
            anytime to jump</span>
        </p>
      </div>
      <div className="recently-visited-container mt-5">
        <div className=" d-flex flex-column flex-md-row flex-wrap justify-content-center mt-4">
          {projects.map((project) => (
            <Link
              to={{
                pathname: `/project`,
                state: { tools: project.tools }, // Pass necessary state
              }}
              className="text-decoration-none"
              key={project.id}
            >
              <div className="card announcement-card m-2 w-md-auto">
                <div className="homecard-body p-3">
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                    <div className="w-100">
                      <div className="badge bg-warning text-white mb-2">
                        {project.category}
                      </div>
                      <h5 className="card-title text-white">{project.name}</h5>
                      <p className="card-text text-white">{project.description}</p>
                    </div>
                    <div className="d-flex justify-content-end mt-2 mt-md-0">
                      <button
                        className="pin-button"
                        onClick={(e) => {
                          e.preventDefault(); // Prevents link navigation when pin button is clicked
                          togglePin(project.id); // Ensure project.id is correctly passed
                        }}
                      >
                        {project.pinned ? <GiPin className="color-icon" /> : <GiPin className="pin-icon" />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
          <hr className="line" />
          <span className="label text-center">RECENTLY VISITED</span>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row flex-wrap justify-content-center mt-4">
        {projects.map((project) => (
          <Link
            to={{
              pathname: `/project`,
              state: { tools: project.tools }, // Pass necessary state
            }}
            className="text-decoration-none"
            key={project.id}
          >
            <div className="card announcement-card m-2 w-md-auto">
              <div className="homecard-body p-3">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div className="w-100">
                    <div className="badge bg-warning text-white mb-2">
                      {project.category}
                    </div>
                    <h5 className="card-title text-white">{project.name}</h5>
                    <p className="card-text text-white">{project.description}</p>
                  </div>
                  <div className="d-flex justify-content-end mt-2 mt-md-0">
                    <button
                      className="pin-button"
                      onClick={(e) => {
                        e.preventDefault(); // Prevents link navigation when pin button is clicked
                        togglePin(project.id); // Ensure project.id is correctly passed
                      }}
                    >
                      {project.pinned ? <GiPin className="color-icon" /> : <GiPin className="pin-icon" />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="container mt-5 d-flex justify-content-center HomeCardCont">
        <div className="card card1">
          <div className="card-header bg-transparent border-0 text-center">
            <div className="schedule-header">
              <span>YOUR SCHEDULE</span>
            </div>
          </div>
          <div className="card-body text-center">
            <div className="calendar mt-2">
              <Calendar
                className="text-light bg-dark calendar"
                onChange={onChange}
                value={date}
              />
              <div className="calendar-footer d-flex justify-content-between mt-3">
                <p className='text-light'>
                  <FontAwesomeIcon className='text-warning me-2' icon={faCalendarDays} />
                  {date.toDateString()}
                </p>
                <p className='text-light'>Nothing's on the schedule</p>
              </div>
            </div>
          </div>
        </div>
        <div className="card card1">
          <div className="card-header bg-transparent border-0 text-center">
            <div className="assignments-header">
              <span>YOUR ASSIGNMENTS</span>
            </div>
          </div>
          <div className="card-body text-center mt-5">
            <FontAwesomeIcon icon={faCircleCheck} className="home-shedule" />
            <p className="mt-3 text-secondary">
              You don’t have any assignments right now. To-dos and cards assigned to you will show up here.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;