import React,{ useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase,faClock,faCircleUser,faGaugeHigh,faCircleCheck,faBell,faCalendar,faStopwatch,} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import Someones_Activity from './Someones_Activity';
import Latest_activity from './Latest_activity';
import Someones_Assignment from './Someones_Assignment';
import Mission_control from './Mission_control';
import To_dos from './To_dos';
import Overdue from './Overdue';
import UnassignedWork from './UnassignedWork';
import UpcomingDates from './UpcomingDates';
import TimeSheet from './TimeSheet';

const Activity = () => {

    const [activeComponent, setActiveComponent] = useState(null);
    
  return (
  <div className='Activity'>
    <div className='header-1 m-0 row'>
        <div className="col-12 d-flex justify-content-center align-item-center">
            <Link className='top-btn' to="" onClick={() => setActiveComponent('Latest_activity')}><FontAwesomeIcon className='text-warning' icon={faClock} /> All the latest activity</Link>  
            <Link className='top-btn' to="" onClick={() => setActiveComponent('Someones_Activity')}><FontAwesomeIcon className='text-danger' icon={faBriefcase} /> Someone’s activity</Link>
            <Link className='top-btn' to="" onClick={() => setActiveComponent('Someones_Assignment')}><FontAwesomeIcon className='text-info' icon={faCircleUser} /> Someone’s assignments</Link>
            <Link className='top-btn' to="" onClick={() => setActiveComponent('Mission_control')}><FontAwesomeIcon className='text-info' icon={faGaugeHigh} /> Mission Control</Link>
        </div>
    </div>
    <div className='header-2 m-0 row'>
        <div className="col-12 d-flex justify-content-center align-item-center">
            <Link className='top-btn' to="" onClick={() => setActiveComponent('To_dos')}><FontAwesomeIcon className='text-success' icon={faCircleCheck} /> To-dos added & completed</Link>  
            <Link className='top-btn' to="" onClick={() => setActiveComponent('Overdue')}><FontAwesomeIcon className='text-primary' icon={faBriefcase} /> Overdue to-dos</Link>
            <Link className='top-btn' to="" onClick={() => setActiveComponent('UnassignedWork')}><FontAwesomeIcon className='text-warning' icon={faBell} /> Unassigned work</Link>
            <Link className='top-btn' to="" onClick={() => setActiveComponent('UpcomingDates')}><FontAwesomeIcon className='text-danger' icon={faCalendar} /> Upcoming dates</Link>
            <Link className='top-btn' to="" onClick={() => setActiveComponent('TimeSheet')}><FontAwesomeIcon className='text-success' icon={faStopwatch} /> Timesheets</Link>
        </div>
    </div>


    {activeComponent === 'Latest_activity' && <Latest_activity />}
    {activeComponent === 'Someones_Activity' && <Someones_Activity />}
    {activeComponent === 'Someones_Assignment' && <Someones_Assignment />}
    {activeComponent === 'Mission_control' && <Mission_control />}

    {activeComponent === 'To_dos' && <To_dos />}
    {activeComponent === 'Overdue' && <Overdue />}
    {activeComponent === 'UnassignedWork' && <UnassignedWork />}
    {activeComponent === 'UpcomingDates' && <UpcomingDates />}
    {activeComponent === 'TimeSheet' && <TimeSheet />}
</div>
  )
}

export default Activity