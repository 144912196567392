import React from 'react'
import { Link } from 'react-router-dom'

const MergePeople = () => {
  return (
    <div className='MergePeople'>
            <div className=' text-center d-block m-auto card--head' style={{ width: '65vw', height: '6vh' }}>
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <Link to='/adminland' className='m-2 fw-bold'>Adminland</Link>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="body-bottom text-center" style={{ width: '75vw' }}>
                        <div className="container ">
                            <div className="">
                                <p className='h2 fw-bold pt-3'>Merge People</p>
                            </div>
                            <hr />
                            <div className='body-content'>
                              <p className='px-5 pt-3 text-start'>Merge one person on your account into another. Use this when there are duplicates or when you want a new person to inherit someone else’s project access and assignments.</p>
                              <p className='px-5 py-2 text-start'><strong>How it works</strong> <br /> You’ll pick two people: a winner and a loser. The winner will receive all of the loser’s assignments, reminders, notification subscriptions, and access to projects. They will not inherit the loser’s historical activity, nor will they gain access to their Ping conversations. After merging the loser will be removed from the account.</p>
                            </div>
                            <div className='body-input mx-5 mb-3 p-3 text-start'>
                              <p className=''>Step 1: Choose the loser of the merge</p>
                              <input type="text" className='form-control' placeholder="Type a person's name..."/>
                              <p className=' text-secondary'>The loser will be merged into the winner and then the loser will be removed.</p>
                              <button className='nxt-btn p-2'>Next Step..</button>
                            </div>
                        </div>
               </div>
            </div>
    </div>
  )
}

export default MergePeople