import React from 'react'
import { RiKey2Fill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { CheckCircle, TableChart, Message, Description, Schedule, Chat, Help, Mail } from '@mui/icons-material';
import { Container, Typography, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const sections = [
    { icon: <CheckCircle color="success" />, title: "To-dos", default: "To-dos" },
    { icon: <TableChart color="warning" />, title: "Card Table", default: "Card Table" },
    { icon: <Message color="primary" />, title: "Message Board", default: "Message Board" },
    { icon: <Description color="secondary" />, title: "Docs & Files", default: "Docs & Files" },
    { icon: <Schedule color="error" />, title: "Schedule", default: "Schedule" },
    { icon: <Chat color="info" />, title: "Chat", default: "Chat" },
    { icon: <Help color="action" />, title: "Automatic Check-ins", default: "Automatic Check-ins" },
    { icon: <Mail color="disabled" />, title: "Email Forwards", default: "Email Forwards" }
];
const RenameProjectTools = () => {
    return (
        <div>
            <div className='campfire'>
                <div className=' text-center d-block m-auto campfire card--head camp' style={{ width: '65vw', height: '6vh' }}>
                    <div className='d-flex justify-content-center align-items-center mt-2'>
                        <Link to="/Adminland" className="fw-bold"><RiKey2Fill style={{
                            fontSize: "20"
                        }} />Adminland</Link>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center camp">
                    <div className="card " style={{ width: '75vw' }}>
                        <div className="card bg-dark campfireBody">
                            <div className="container ">
                                <div className=" text-center mt-3">
                                    <a href="" className='headcamp fw-bold'> <h3 className="mb-0 text-light">Rename project tools</h3> </a>
                                </div>
                                <hr className="line" />
                            </div>
                            <div className="card-body bg-dark p-0">
                                <div className="admin-panel text-light">
                                    <Container>
                                        <Typography variant="" gutterBottom>
                                            Here you can customize the default project tools. For example, if you'd like to change "Schedule" to "Timeline" or "Message Board" to "Announcements", you can rename them once and the new names will be used moving forward.
                                        </Typography>
                                        <Typography variant="body2" className='mt-3 text-light' paragraph>
                                            Note: Changes you make below will be used in new projects and templates and WILL NOT affect existing projects and templates.
                                        </Typography>
                                        <List className='text-light'>
                                            {sections.map((section, index) => (
                                                <ListItem key={index}>
                                                    <ListItemIcon>{section.icon}</ListItemIcon>
                                                    <ListItemText className='text-light' primary={section.title} secondary={`Default: ${section.default}`} />
                                                    <IconButton edge="end">
                                                        <EditIcon className='text-light' />
                                                    </IconButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                        <Typography variant="body2" color="primary" style={{ cursor: 'pointer' }}>
                                            Reset all to default names
                                        </Typography>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RenameProjectTools
