import React from 'react'
import { MdAutoDelete, MdCancel, MdDelete, MdEdit, MdGroups2, MdMerge, MdOutlineMessage, MdPeople, MdVpnKey } from "react-icons/md";
import { PiArrowArcRightFill } from "react-icons/pi";
import { IoLockOpen, IoBriefcase, IoFileTrayFullSharp } from "react-icons/io5";
import { RiArchive2Fill, RiKey2Fill } from "react-icons/ri";
import { IoIosChatboxes, IoIosLink, IoMdPeople, IoMdPersonAdd } from "react-icons/io";
import { FaCrown, FaDollarSign, FaEdit } from "react-icons/fa";
import { BsFillBuildingFill } from "react-icons/bs";
import { TbArrowsDoubleNeSw } from "react-icons/tb";
import { Link } from 'react-router-dom';

const Adminland = () => {
    return (
        <div>
            <div className="container campfireBody">
                <div className="row align-items-center">
                    <div className="col">
                    </div>
                    <div className="text-center">
                        <h3 className="mb-0 mt-3 headcamp text-light fw-bold"><RiKey2Fill style={{
                            fontSize: "35"
                        }} />Adminland</h3>
                    </div>
                    <div className="">
                        <p className='fs-5 text-center text-light'>Manage your TeamNex account</p>
                    </div>
                    <hr className="adminlandLine" />
                </div>
                <div className='container p-5 admin-panel'>
                    <p className='text-light fs-4'>What’s this?</p>
                    <p className='text-light'>Adminland is the place for admins and owners to oversee this TeamNex account.
                        They have special permissions to add/remove people, change billing info, export data, etc.
                        Need something like that done? Ping someone listed below.</p>
                    <p className='text-light fs-4'>Administrators</p>
                    <span className=' text-light'>
                        <img src="" alt='' className="profile-pic" />
                        Arun
                        <img src="" alt='' className="profile-pic" />
                        Arun
                        <img src="" alt='' className="profile-pic" />
                        Arun
                        <img src="" alt='' className="profile-pic" />
                        Arun
                    </span>
                    <h5 className='text-light mt-5'>You’re an admin, so you can…</h5>
                    <Link to="/add_Remove_Acc" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><MdPeople className='text-light' /> </button>Add/remove people or change their access
                    </Link>
                    <hr className="textLine ms-5 " />
                    <Link to='/administrators' className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><IoBriefcase className='text-light' /> </button>Add/remove administrators
                    </Link><hr className="textLine ms-5" />
                    <Link to="/invite_Link" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><IoMdPersonAdd className='text-light' /> </button>Invite coworkers with a link
                    </Link><hr className="textLine ms-5" />
                    <Link to="/manageGroups" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><MdGroups2 className='text-light' /> </button>Manage groups
                    </Link><hr className="textLine ms-5" />
                    <Link to="/manageCompanies" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><BsFillBuildingFill className='text-light' /> </button>Manage companies
                    </Link><hr className="textLine ms-5" />
                    <Link to="/renameProjectTools" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><FaEdit className='text-light' /> </button>Rename project tools
                    </Link><hr className="textLine ms-5" />
                    <Link to="/messageCategories" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><MdOutlineMessage className='text-light' /> </button>Change message categories
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/moveProject" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><PiArrowArcRightFill className='text-light' /> </button>Move projects from TeamNex 2 to TeamNex 4
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/mergePeople" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><MdMerge className='text-light' /> </button>Merge people
                    </Link>
                    <p className='text-light fs-4 mt-5'>Account Owners</p>
                    <span className=' text-light'>
                        <img src="" alt='' className="profile-pic" />
                        Arun
                        <img src="" alt='' className="profile-pic" />
                        Arun
                        <img src="" alt='' className="profile-pic" />
                        Arun
                        <img src="" alt='' className="profile-pic" />
                        Arun
                    </span>
                    <h5 className='text-light mt-5'>Admin Pro Pack, included with Pro Unlimited</h5>
                    <Link to="/changePings" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><IoIosChatboxes className='text-light' /> </button>Change who can send pings
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/editProjectSettings" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><MdEdit className='text-light' /> </button>Change who can edit project settings
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/editProject" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><IoMdPeople className='text-light' /> </button>Change who can edit the people on a project
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/archive_And_Delete_Project" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><RiArchive2Fill className='text-light' /></button>Change who can archive and delete projects, docs, and more
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/publicLink" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><IoIosLink className='text-light' /> </button>Change who can turn on public links
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/two_Factor_Authentication" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><IoLockOpen className='text-light' /></button>Require two-factor authentication
                    </Link>
                    <hr className="textLine ms-5 " />
                    <Link to="/ping_And_Chat_History" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandadminBtn me-2'><MdAutoDelete className='text-light' /> </button>Change Ping & Chat history settings
                    </Link>
                    <hr className="textLine ms-5" />
                    <h5 className='text-light mt-5'>You’re an account owner, so you can…</h5>
                    <Link to="/planSelection" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><FaDollarSign className='text-light' /></button>Buy TeamNex today
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/manageStorage" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><IoFileTrayFullSharp className='text-light' /> </button>Manage storage
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/add_Remove_Acc" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><FaCrown className='text-light' /></button>Add/remove account owners
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/account_Rename" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><MdEdit className='text-light' /></button>Rename this account (TeamNex)
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/trash" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><MdDelete className='text-light' /></button>View everything in the trash
                    </Link><hr className="textLine ms-5" />
                    <Link to="/reassignment" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><TbArrowsDoubleNeSw className='text-light' /></button>Reassign someone’s to-dos
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/projectAccess" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><MdVpnKey className='text-light' /> </button>Access any project
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/export_Data" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><RiArchive2Fill className='text-light' /> </button>Export data from this account
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/managePublicItems" className='text-light mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><IoIosLink className='text-light' /> </button>Manage public items
                    </Link>
                    <hr className="textLine ms-5" />
                    <Link to="/cancelAccount" className='text-light p-0 mt-3 text-decoration-none'>
                        <button className='adminlandownerBtn me-2'><MdCancel className='text-light' /></button>Cancel the account
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Adminland;