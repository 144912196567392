import React,{useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight,faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const To_dos = () => {
    const [date, setDate] = useState(new Date());

  const previousDay = () => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() - 1);
    setDate(newDate);
  };

  const nextDay = () => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + 1);
    setDate(newDate);
  };

  const goToToday = () => {
    setDate(new Date());
  };

  const formatDate = (date) => {
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options).toUpperCase();
  };
  return (
    // <div className="calendar-header">
    //   <button onClick={previousDay}>&larr;</button>
    //   <span>{formatDate(date)}</span>
    //   <button onClick={nextDay}>&rarr;</button>
    // </div>
     <div className='To-do container w-75 vh-100'>
                <div className="row align-items-center p-2">
                  <div className="col">
                    
                  </div>
                  <div className="col text-center mt-3">
                          <button className='right-arr me-3' onClick={previousDay}><FontAwesomeIcon icon={faArrowLeft} /></button>
                          <span>{formatDate(date)}</span>
                          <button className='left-arr ms-3' onClick={nextDay}><FontAwesomeIcon icon={faArrowRight} /></button>
                  </div>
                  <div className="col text-end">
                      <button className="today-button" onClick={goToToday}>TODAY</button>
                  </div>
                </div>
                <div className='d-flex justify-content-center h3 fw-bold'>
                    <p>To-dos added & completed</p>
                </div>


                <div className='row'>
                    <div className='col d-flex justify-content-center pt-2 col-box1'>
                        <p>No to-dos were added</p>
                    </div>
                    <div className='col  d-flex justify-content-center pt-2 col-box2 '>
                        <p>No to-dos were added</p>
                    </div>
                </div>
    
    </div>
  )
}

export default To_dos